<template>
  <div class="mt-filters-item">
    <div
      class="mt-filters-item__header"
      @click="toggleShow"
    >
      <div class="mt-filters-item__info">
        <div class="mt-filters-item__title">
          <r-title type="subtitle-2">
            {{ filter.title }}
          </r-title>
        </div>
        <div
          class="mt-filters-item__count"
          :class="{
            active: activeCount && activeCount !== filter.items.length
          }"
        >
          <r-text
            v-if="activeCount"
            type="caption"
          >
            Активно: {{ activeCount }} из {{ filter.items.length }}
          </r-text>
          <r-text
            v-else
            type="caption"
          >
            Фильтр неактивен
          </r-text>
        </div>
      </div>
      <div class="mt-filters-item__icon">
        <r-icon
          :name="filter.show ? 'chevron-up' : 'chevron-down'"
          :size="24"
        />
      </div>
    </div>
    <div
      v-show="filter.show"
      class="mt-filters-item__container"
    >
      <div class="mt-filters-item__search">
        <r-search-input
          :filter-text="filterText"
          @change="searchChange"
        />
      </div>
      <ul class="mt-filters-item__values">
        <li class="mt-filters-item__value mt-filters-item__value--all">
          <r-text
            color-type="secondary"
            class="mt-filters-item__value-info"
            @click="toggleAll"
          >
            &lt;Все&gt;
          </r-text>
          <el-checkbox
            :value="allValue"
            :indeterminate="!isAllActive && activeCount !== 0"
            class="r-checkbox mt-filters-item__checkbox"
            @change="toggleAll"
          />
        </li>
        <li
          v-for="value in filteredItems"
          :key="value.id"
          :class="getCategoryClass(value)"
          class="mt-filters-item__value"
        >
          <div class="mt-filters-item__value-info">
            <r-block
              no-padding
              start
              col
            >
              <r-icon
                v-if="value.icon"
                :name="value.icon"
                :size="20"
              />
              <r-text>
                {{ getCategoryLabel(value.name) }}
              </r-text>
            </r-block>
            <r-text
              type="caption"
              class="mt-filters-item__value-stats"
            >
              {{ value.count }}
            </r-text>
          </div>
          <el-checkbox
            v-model="value.active"
            class="r-checkbox mt-filters-item__checkbox"
            @change="changeHandler(filter)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const icons = {
  'Внутренние ТС': 'car-filled',
  Автобусы: 'bus-filled',
  Легковые: 'car-filled',
  Грузовые: 'truck-filled',
  'Дорожно-строительная техника': 'bulldozer-filled',
  'Подъёмные сооружения': 'tow-truck',
  'ТС с ТМЦ': 'truck-filled',
  'Пассажирский транспорт': 'bus-filled',
  Баржи: 'ship-filled',
  default: 'car-filled'
}
export default {
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filterText: ''
    }
  },
  computed: {
    allValue() {
      return this.activeCount > 0
    },
    isAllActive() {
      return this.filter.items.every(f => !!f.active)
    },
    activeCount() {
      return this.filter.items.filter(f => f.active).length
    },
    isOneActive() {
      return this.activeCount === 1
    },
    filteredItems() {
      return this.filter.items.filter(
        ({ name }) =>
          (name || 'Не указано')
            .toLowerCase()
            .indexOf(this.filterText.toLowerCase()) > -1
      )?.map(f => {
        if (this.filter.type === 'type_group_id') {
          f.icon = icons[f.name]
        }
        return f
      })
    }
  },
  methods: {
    searchChange(value) {
      this.filterText = value
    },
    changeHandler(value) {
      this.$emit('change-handler', value)
    },
    toggleShow() {
      this.$emit('toggle-show', this.filter)
    },
    getCategoryLabel(name) {
      if (!name || name === 'NULL') {
        return 'Не указано'
      }

      return name
    },
    getCategoryClass({ count }) {
      return count > 0 ? '' : 'mt-filters-item__value--disabled'
    },
    toggleAll() {
      const flag = !this.isAllActive

      this.filter.items.forEach(value => {
        this.$set(value, 'active', flag)
      })

      this.$emit('change-handler', this.filter)
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-filters-item {
  background-color: var(--bg_containers);
  border-radius: var(--border-radius);
  padding: 8px;
  margin-top: 8px;

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__icon {
    margin-left: auto;
  }

  &__count.active {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translate(0, -50%);
      height: 6px;
      width: 6px;
      background-color: var(--accent_primary);
      border-radius: var(--border-radius);
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    margin-top: 4px;
  }

  &__toggle {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &__values {
    padding: 8px;
  }

  &__value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-info {
      flex: 1;
    }

    &--all {
      cursor: pointer !important;

      .el-checkbox__input.is-disabled .el-checkbox__inner,
      .el-checkbox__input.is-disabled .el-checkbox__inner::after {
        cursor: pointer !important;
      }
    }

    &:not(:first-child) {
      margin-top: 8px;
    }

    &.mt-filters-item__value--disabled {
      opacity: 0.5;
    }
  }

  &__checkbox {
    margin-left: 8px;
  }
}
</style>
