export const applicationModel = [
  {
    model: 'main_contractor_id',
    title: 'Наименование подрядной / субподрядной организации',
    placeholder: 'АО "НИПИГАЗ"',
    type: 'select',
    filterable: true,
    select: 'main_contractor_id'
  },
  {
    model: 'contractor_repr_id',
    additionalField: 'position',
    title: 'Представитель подрядной организации',
    type: 'select',
    filterable: true,
    select: 'employees',
    filter: '!is_prime'
  },
  {
    model: 'working_places',
    title: 'Место осмотра',
    placeholder: 'ПОТ',
    type: 'input'
  },
  {
    model: 'inspection_comment',
    title: 'Комментарий инспектора',
    placeholder: 'Недостаточное давление в шинах',
    type: 'input'
  },
  {
    model: 'driver_full_name',
    parent: 'vehicle',
    title: 'ФИО водителя',
    placeholder: 'Петров П.П.',
    type: 'input'
  },
  {
    model: 'model_id',
    parent: 'vehicle',
    title: 'Марка, модель транспортного средства',
    placeholder: 'УАЗ 2110',
    type: 'select',
    filterable: true,
    select: 'vehicle_models'
  },
  {
    model: 'vehicle_type_id',
    parent: 'vehicle',
    title: 'Вид ТС',
    placeholder: 'Легковой',
    type: 'select',
    filterable: true,
    select: 'vehicle_types'
  },
  {
    model: 'vehicle.vehicle_type.vehicle_type_group.name',
    hardLink: true,
    parent: 'vehicle',
    title: 'Тип ТС',
    type: 'select',
    read_only: true
  },
  {
    model: 'reg_number',
    parent: 'vehicle',
    title: 'Государственный регистрационный знак',
    required: true,
    placeholder: 'А 123 АА 123',
    type: 'input'
  },
  {
    model: 'driver_phone',
    parent: 'vehicle',
    title: 'Телефон водителя',
    placeholder: '+7 999 876 5432',
    type: 'input'
  },
  {
    model: 'main_contractor_id',
    parent: 'vehicle',
    title: 'Наименование подрядной / субподрядной организации',
    placeholder: 'АО "НИПИГАЗ"',
    type: 'select',
    filterable: true,
    select: 'main_contractor_id'
  },
  {
    model: 'vin',
    parent: 'vehicle',
    title: 'Идентификационный номер',
    placeholder: 'Введите VIN',
    type: 'input'
  },
  {
    model: 'year',
    parent: 'vehicle',
    title: 'Год выпуска транспортного средства',
    placeholder: 'Введите год',
    type: 'input',
    mask: 'YYYY'
  },
  {
    model: 'mileage',
    parent: 'vehicle',
    title: 'Общий пробег с начала эксплуатации',
    placeholder: 'Введите пробег',
    type: 'input'
  },
  {
    model: 'app_id',
    title: 'Номер заявки',
    width: 'half',
    placeholder: '13',
    type: 'input',
    dataType: 'number'
  },
  {
    model: 'ksot_app_id',
    title: 'Номер заявки в КСОТ',
    width: 'half',
    placeholder: '13',
    type: 'input',
    dataType: 'number'
  },
  {
    model: 'is_ksot_app',
    title: 'Заявка загружена из КСОТ',
    width: 'half',
    type: 'boolean'
  },
  {
    model: 'status_id',
    title: 'Статус',
    placeholder: 'Новая заявка',
    type: 'select',
    select: 'applications_status_list'
  },
  {
    model: 'applications_employees',
    key: 'employee_id',
    additionalField: 'position',
    title: 'Участники осмотра',
    type: 'select',
    filterable: true,
    select: 'employees',
    multiple: true
  }
]

export const applicationsConfig = {
  include: {
    contractor: { only: ['name'] },
    vehicle: {
      only: [
        'reg_number',
        'driver_full_name',
        'main_contractor_id',
        'driver_phone'
      ],
      include: {
        model: { only: ['name'] },
        contract: { only: ['number', 'date'] },
        vehicle_type: {
          only: ['name'],
          include: {
            vehicle_type_group: { only: ['name'] }
          }
        }
      }
    },
    applications_employees: {},
    status: { only: ['name'] }
  }
}

export const includeColumns = [
  'i',
  'created_at',
  'inspection_dt',
  'reg_number',
  'status_name',
  'contractor_name',
  'vehicle_type_group',
  'driver_full_name',
  'driver_phone',
  'inspection_comment'
]

export const fieldsExplanation = {
  i: '№',
  created_at: 'дата получения',
  inspection_dt: 'плановая дата осмотра',
  inspection_dt_fact: 'фактическая дата осмотра',
  reg_number: 'гос.номер',
  status_name: 'Статус',
  contractor_name: 'подрядчик',
  vehicle_type_group: 'Тип транспорта',
  driver_full_name: 'Фио водителя',
  driver_phone: 'Телефон водителя',
  inspection_comment: 'Комментарий'
}

export const exportConfig = {
  order: [{ created_at: 'desc' }],
  with_numeration: true,
  column_order: [
    'created_at',
    'inspection_dt',
    'inspection_dt_fact',
    'vehicle.reg_number',
    'status.name',
    'contractor.name',
    'vehicle.vehicle_type.vehicle_type_group.name',
    'vehicle.driver_full_name',
    'vehicle.driver_phone',
    'inspection_comment'
  ],
  column_labels: [
    'Дата получения',
    'Дата осмотра план',
    'Дата осмотра факт',
    'Гос.номер',
    'Статус',
    'Подрядчик',
    'Тип транспорта',
    'Фио водителя',
    'Телефон водителя',
    'Комментарий'
  ],
  only: [
    'created_at',
    'inspection_dt',
    'inspection_dt_fact',
    'inspection_comment'
  ],
  include: {
    status: { only: ['name'] },
    contractor: { only: ['name'] },
    vehicle: {
      only: ['reg_number', 'driver_full_name', 'driver_phone'],
      include: {
        vehicle_type: { only: ['name'] }
      }
    }
  }
}

export const docs = [
  {
    name: 'Водительское удостоверение',
    attr_name: 'driver_licence'
  },
  {
    name: 'Паспорт ТС (ПТС)',
    parent_source: 'vehicle',
    attr_name: 'passport'
  },
  {
    name: 'Свидетельство о регистрации ТС (СТС)',
    parent_source: 'vehicle',
    attr_name: 'vrc_sn'
  },
  {
    name: 'Диагностическая карта',
    attr_name: 'diagnostic_card'
  },
  {
    name: 'Страховой полис ОСАГО',
    attr_name: 'insurance_policy_osago'
  },
  {
    name: 'Путевой лист',
    attr_name: 'waybill'
  },
  {
    name: 'Страховой полис перевозчика',
    attr_name: 'driver_insurance_policy'
  },
  {
    name: 'Договор фрахтования',
    attr_name: 'charter_agreement'
  },
  {
    name: 'Карта водителя',
    attr_name: 'driver_card'
  }
]
