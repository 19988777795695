import mapboxgl from 'mapbox-gl'
import { mapToken } from '@/constants/tokens'
import { ActiveObject } from '../controllers'
import {
  MovementParkings,
  MovementsStat,
  PassingReportHourlyIn,
  PassingReportHourlyOut,
  PassingReportHourlyAll,
  PassingReportForPeriodIn,
  PassingReportForPeriodOut,
  PassingReportForPeriodAll,
  PassingReportForPeriodByType,
  PassingReportLoadForPeriod, CrossingsGeozones
} from '../../../controllers'
import { loadIcons } from '../../../helpers'
import { getMapConfigs } from '@/utils'

export const initMap = (component, callback) => {
  const { baselayer } = getMapConfigs(component, 'analytics')

  mapboxgl.accessToken = mapToken

  const center = { lat: 51.53, lng: 128.183 }
  const zoom = 13

  component.mapgl = new mapboxgl.Map({
    container: 'an-map',
    preserveDrawingBuffer: true,
    style: baselayer,
    center,
    zoom
  })

  component.mapgl.dragRotate.disable()
  component.mapgl.on('load', async() => {
    await loadIcons(component)
    component.mapgl.resize()
    component.controllers.active_object = new ActiveObject(
      component
    )
    component.controllers.movement_and_parkings = new MovementParkings(
      component
    )
    component.controllers.crossings_geozones = new CrossingsGeozones(
      component
    )
    component.controllers.movements_stat = new MovementsStat(component)
    component.controllers.passing_report_hourly_in = new PassingReportHourlyIn(
      component
    )
    component.controllers.passing_report_hourly_out = new PassingReportHourlyOut(
      component
    )
    component.controllers.passing_report_hourly_all = new PassingReportHourlyAll(
      component
    )
    component.controllers.passing_report_for_period_in = new PassingReportForPeriodIn(
      component
    )
    component.controllers.passing_report_for_period_out = new PassingReportForPeriodOut(
      component
    )
    component.controllers.passing_report_for_period_all = new PassingReportForPeriodAll(
      component
    )
    component.controllers.passing_report_for_period_by_type = new PassingReportForPeriodByType(
      component
    )
    component.controllers.passing_report_load_for_period = new PassingReportLoadForPeriod(
      component
    )
    component.mapData = await component.controllers[
      component.report.value
    ].getMapData(component.reportData)

    component.controllers[component.report.value].addMapData(component.mapData)

    if (component.activeObject) {
      component.controllers.active_object.addLayer(component.activeObject)
    }

    if (callback) {
      callback()
    }
  })
}
