<template>
  <div class="an-content-controls">
    <r-button
      v-if="!report.noEdit"
      v-popover:settingsPopover
      icon="settings"
    >
      Изменить параметры отчёта
    </r-button>
    <el-popover
      ref="settingsPopover"
      v-model="popovers.settings"
      popper-class="r-popover an-custom-popover"
      placement="bottom-start"
      width="400"
      trigger="click"
    >
      <an-options
        no-control
        :style="optionsStyle"
      />
    </el-popover>
    <an-report-info />
    <el-popover
      ref="workspacePopover"
      v-model="popovers.workspace"
      :popper-class="`r-popover simple`"
      placement="bottom-start"
      width="300"
      trigger="click"
    >
      <an-workspace-tool />
    </el-popover>
    <r-block
      no-padding
      col
    >
      <r-button
        v-if="typesList && typesList.length > 1"
        v-popover:workspacePopover
        icon="window-M"
        dropdown
      >
        {{ $t('workspace') }}
      </r-button>
      <r-button
        v-if="statUpdateClass"
        :disabled="dataUpdateEnabled"
        @click="updateData"
      >
        Обновить данные за выбранный период (в фоне)
      </r-button>
    </r-block>
  </div>
</template>

<script>
import anWorkspaceTool from './components/an-workspace-tool'
import anReportInfo from './components/an-report-info'
import anOptions from '../an-select-modal/an-select-modal-report-options'
import { reportOptions } from '../../configs'

export default {
  components: {
    anOptions,
    anReportInfo,
    anWorkspaceTool
  },
  data() {
    return {
      popovers: {
        all: false,
        date: false,
        workspace: false
      },
      optionsStyle: {
        position: 'relative',
        top: 'auto',
        left: 'auto',
        transform: 'initial'
      },
      reportOptions,
      dataUpdateEnabled: false
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report || null
    },
    typesList() {
      const { workspace } = this.reportOptions[this.report?.value] || null

      return workspace
    },
    statUpdateClass() {
      const { statUpdateClass } = this.reportOptions[this.report?.value] || null

      return statUpdateClass
    },
    datetime() {
      return this.$store.state.analytics.selectedDate || null
    },
    initialServicesData() {
      return this.$store.state.analytics.servicesData || null
    }
  },
  watch: {
    initialServicesData: {
      handler: function() {
        this.dataUpdateEnabled = false
      },
      deep: true
    }
  },
  methods: {
    async updateData() {
      await this.$store.dispatch('POST_REQUEST', {
        url: 'report_stat',
        data: this.getUpdateData()
      })
      this.dataUpdateEnabled = true
    },
    getUpdateData() {
      const options = {
        worker_class_name: this.statUpdateClass,
        from: decodeURIComponent(this.datetime.from_dt),
        to: decodeURIComponent(this.datetime.to_dt)
      }

      if (!this.initialServicesData) return options

      Object.values(this.initialServicesData).filter(d => d.value?.length).map(d => {
        options[d.urlField] = d.value
      })

      return options
    }
  }
}
</script>

<style lang="scss">
.an-content-controls {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast);
  height: 64px !important;

  > * {
    &:last-child {
      margin-left: auto;
    }
  }
}
.r-popover.an-custom-popover {
  padding: 0 !important;
}
</style>

<i18n>
{
  "ru": {
    "filters": "Все фильтры",
    "filters-date": "Фильтр по дате",
    "workspace": "Рабочая область"
  },
  "en": {
    "filters": "Filters",
    "filters-date": "Date filters",
    "workspace": "Workspace"
  }
}
</i18n>
