<template>
  <div class="track-player">
    <r-button
      :class="['track-player__closeBtn', { show: trackPlayerOn }]"
      :icon="!trackPlayerOn ? 'play' : 'close-delete'"
      :simple="trackPlayerOn"
      @click="togglePlayer"
    >
      {{ !trackPlayerOn ? 'Проигрывание трека' : '' }}
    </r-button>
    <r-block
      v-if="trackPlayerOn"
      class="track-player-content"
    >
      <r-title
        center
        type="title-3"
      >
        {{ $ritmDate.toFormat(currentTime, 'HH:mm:ss DD.MM.YY') }}
      </r-title>
      <r-slider
        no-input
        :number="current"
        :min="min"
        :max="max"
        :step="speed"
        @change="changeTimeValue"
      />
      <r-block
        no-padding
        center
        col
      >
        <r-button
          simple
          icon="double-chevron-left"
          :disabled="currentTime <= min"
          @click="manualMove(-speed)"
        />
        <r-button
          simple
          :icon="playing ? 'pause' : 'play'"
          @click="togglePlay"
        />
        <r-button
          simple
          icon="double-chevron-right"
          :disabled="currentTime >= max"
          @click="manualMove(speed)"
        />
      </r-block>
      <el-select
        v-model="speed"
        class="r-select"
      >
        <el-option
          v-for="item in speedList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </r-block>
  </div>
</template>

<script>
import { TrackPlayerController } from '../map/controllers/'
import cloneDeep from 'lodash.clonedeep'
import extent from 'turf-extent'

export default {
  props: {
    mapgl: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      playing: false,
      speed: 1000,
      current: 0,
      playInstance: null,
      controller: null,
      currentTime: null,
      speedList: [
        { id: 1000, name: 'х1' },
        { id: 2000, name: 'х2' },
        { id: 4000, name: 'х4' },
        { id: 8000, name: 'х8' }
      ]
    }
  },
  computed: {
    mainTrackGeom() {
      return this.$store.state.monitoring.mainTrackGeom?.filter(e => e.properties.time) || null
    },
    secondTrackGeom() {
      return this.$store.state.monitoring.secondTrackGeom?.filter(e => e.properties.time) || null
    },
    mainTrackTimeline() {
      return this.mainTrackGeom?.map(e => this.$ritmDate.toUnix(e.properties.time))
    },
    secondTrackTimeline() {
      return this.secondTrackGeom?.map(e => this.$ritmDate.toUnix(e.properties.time))
    },
    tracksData() {
      const tracks = []

      if (this.mainTrackGeom?.length) {
        tracks.push(this.mainTrackGeom?.map(e => {
          return e.properties.time
        })?.filter(e => e) || [])
      }

      if (this.secondTrackGeom?.length) {
        tracks.push(this.secondTrackGeom?.map(e => {
          return e.properties.time
        })?.filter(e => e) || [])
      }

      return tracks
    },
    timeline() {
      const { from, to } = this.tracksData && this.tracksData.reduce((a, c) => {
        const { min, max } = (Array.isArray(c) ? c : []).reduce((acc, cur) => {
          const val = this.$ritmDate.toUnix(cur)

          if (!acc.min || acc.min > val) {
            acc.min = val
          }

          if (!acc.max || acc.max < val) {
            acc.max = val
          }

          return acc
        }, { min: null, max: null })

        if (!a.from || a.from > min) {
          a.from = min
        }

        if (!a.to || a.to < max) {
          a.to = max
        }

        return a
      }, { from: null, to: null })

      return { from, to }
    },
    min() {
      return this.timeline.from || 0
    },
    max() {
      return this.timeline.to || 0
    },
    layerData() {
      const mainTrack = cloneDeep(this.mainTrackGeom)
      const secondTrack = cloneDeep(this.secondTrackGeom)
      const items = []

      if (mainTrack?.length) {
        const nextIndex = this.mainTrackTimeline?.findIndex(e => e > this.currentTime)
        const mainIndex = nextIndex === 0 ? 0 : !nextIndex || nextIndex < 0 ? this.mainTrackTimeline?.length - 1 : nextIndex - 1
        const mainItem = mainTrack?.[mainIndex]

        if (mainItem?.geometry?.coordinates?.[0]) {
          mainItem.properties.type = 'main'
          mainItem.geometry = {
            type: 'Point',
            coordinates: mainItem.geometry.coordinates?.[0]
          }

          items.push(mainItem)
        }
      }

      if (secondTrack?.length) {
        const nextIndex = this.secondTrackTimeline?.findIndex(e => e > this.currentTime)
        const secondIndex = nextIndex === 0 ? 0 : !nextIndex || nextIndex < 0 ? this.secondTrackTimeline?.length - 1 : nextIndex - 1
        const secondItem = secondTrack?.[secondIndex]

        if (secondItem?.geometry?.coordinates?.[0]) {
          secondItem.properties.type = 'second'
          secondItem.geometry = {
            type: 'Point',
            coordinates: secondItem.geometry.coordinates?.[0]
          }
          items.push(secondItem)
        }
      }

      return items
    },
    trackPlayerOn: {
      get() {
        return this.$store.state.monitoring.trackPlayerOn
      },
      set(value) {
        this.$store.commit('SET_MT_FIELD', { field: 'trackPlayerOn', value })
      }
    }
  },
  watch: {
    currentTime() {
      this.controller.updateLayer(this.layerData)
      this.flyTo(this.layerData[0])
    }
  },
  created() {
    this.controller = new TrackPlayerController(this.mapgl)

    this.currentTime = this.timeline.from
  },
  beforeDestroy() {
    this.controller.updateLayer()

    if (this.playInstance) {
      clearInterval(this.playInstance)
    }

    this.trackPlayerOn = false

    this.toggleAdditionalLayers()
  },
  methods: {
    toggleAdditionalLayers(val = this.trackPlayerOn) {
      const layers = ['mt-events-main', 'mt-events-second', 'vehicles', 'vehicles-clusters', 'vehicles-cluster-count']

      layers.forEach(l => {
        if (this.mapgl.getLayer(l)) {
          this.mapgl.setLayoutProperty(
            l,
            'visibility',
            val ? 'none' : 'visible'
          )
        }
      })
    },
    togglePlayer() {
      this.trackPlayerOn = !this.trackPlayerOn

      if (this.trackPlayerOn) {
        this.controller.updateLayer(this.layerData)
      } else {
        this.controller.updateLayer()
      }

      this.toggleAdditionalLayers()
    },
    togglePlay() {
      this.playing = !this.playing

      if (this.playing) {
        this.playInstance = setInterval(() => {
          if (this.currentTime + this.speed >= this.timeline.to) {
            this.currentTime = this.timeline.to
            clearInterval(this.playInstance)
            this.playing = false
          } else {
            this.currentTime += this.speed
          }
        }, 1000)
      } else if (this.playInstance) {
        clearInterval(this.playInstance)
      }
    },
    startPlay() {
      this.current++
    },
    changeTimeValue(val) {
      this.currentTime = val

      this.stopPlay()
    },
    stopPlay() {
      if (this.playing) {
        this.playing = false

        clearInterval(this.playInstance)
      }
    },
    manualMove(val) {
      if (val > 0) {
        if (this.currentTime + this.speed >= this.timeline.to) {
          this.currentTime = this.timeline.to
        } else {
          this.currentTime += this.speed
        }
      } else {
        if (this.currentTime - this.speed >= this.timeline.from) {
          this.currentTime = this.timeline.from
        } else {
          this.currentTime -= this.speed
        }
      }
    },
    flyTo(geom) {
      if (!geom) return

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 14
      })
    }
  }
}
</script>

<style lang="scss">
.track-player {
  position: absolute;
  bottom: 2.5rem;
  left: 0.5rem;
  display: grid;
  padding: 0.5rem;
  background-color: var(--modal_bg);
  border-radius: var(--border-radius);

  &__closeBtn {
    &.show {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-content {
    width: 400px;
  }
}
</style>
