<template>
  <div class="object-filter">
    <el-switch
      v-model="showSings"
      active-text="Знаки на карте"
      class="r-switch"
    />
    <el-switch
      v-model="showEvents"
      active-text="Перекрытия на карте"
      class="r-switch"
    />
    <div
      v-if="tab === 'road_blocks'"
      class="object-filter-category"
    >
      <el-dropdown
        class="r-dropdown"
        :show-timeout="100"
        placement="bottom-start"
        trigger="click"
      >
        <r-button
          dropdown
          uppercase
          bold
          :active="categoryFilter !== 'Все'"
          type="secondary"
        >
          {{ `Тип: ${categoryFilter}` }}
        </r-button>

        <el-dropdown-menu
          slot="dropdown"
          class="r-dropdown-menu r-dropdown--simple-menu"
        >
          <r-text
            class="r-dropdown--simple-menu__title"
            color-type="secondary"
          >
            Тип события:
          </r-text>
          <el-dropdown-item
            v-for="item in categories"
            :key="item.id"
            :class="[
              'r-dropdown--simple-menu__item',
              { active: isActive(item.title) }
            ]"
            :hide-on-click="false"
            @click.native="setFilter(item.title)"
          >
            <r-icon
              v-if="item.icon"
              :name="item.icon"
              :size="24"
            />
            <r-text>
              {{ item.title }}
            </r-text>
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-switch
          v-if="categoryFilter === 'ДТП'"
          v-model="relatedToProd"
          active-text="Связанно с производством"
          class="r-switch"
        />
      </el-dropdown>
    </div>
    <div
      v-if="tab === 'road_blocks'"
      class="object-filter-interval"
    >
      <r-filter-set
        no-reset-button
        :filters="filters"
        @load="updateInterval"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'

const categories = [
  {
    id: 1,
    title: 'Все'
  },
  {
    id: 2,
    title: 'ДТП',
    icon: 'car-accident'
  },
  {
    id: 3,
    title: 'Недостатки',
    icon: 'closed'
  },
  {
    id: 4,
    title: 'Ремонтные работы',
    icon: 'work'
  }
]

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    tab: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      filters: [
        {
          id: 'time',
          type: 'time-filter',
          title: 'Время',
          format: 'HH:mm:ss',
          pickerType: 'time',
          prop: { interval: { from: '', to: '' } },
          active: false
        },
        {
          id: 'date',
          type: 'interval-filter',
          title: 'Дата',
          format: 'dd.MM.yyyy',
          pickerType: 'date',
          prop: { interval: { from: '', to: '' } },
          active: false
        }
      ],
      categories
    }
  },
  computed: {
    relatedToProd: {
      get() {
        return this.$store.state.odd.relatedToProd
      },
      set(value) {
        this.$store.commit('SET_ODD_FIELD', { field: 'relatedToProd', value })
      }
    },
    showEvents: {
      get() {
        return this.$store.state.odd.showEvents
      },
      set(value) {
        this.$store.commit('SET_ODD_FIELD', { field: 'showEvents', value })
      }
    },
    showSings: {
      get() {
        return this.$store.state.odd.showSings
      },
      set(value) {
        this.$store.commit('SET_ODD_FIELD', { field: 'showSings', value })
      }
    },
    categoryFilter() {
      return this.$store.state.odd.eventsFilter?.category || 'Все'
    },
    intervalFilter() {
      return this.$store.state.odd.eventsFilter?.interval || null
    },
    timeFilter() {
      return this.$store.state.odd.eventsFilter?.time || null
    }
  },
  created() {
    if (this.intervalFilter) {
      const payload = cloneDeep(this.intervalFilter)

      this.filters[0].prop = payload.prop
      this.filters[0].active = payload.active
    }
    if (this.timeFilter) {
      const payload = cloneDeep(this.timeFilter)

      this.filters[0].prop = payload.prop
      this.filters[0].active = payload.active
    }
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    reset() {
      this.setFilter('Все')
      this.filters.forEach(f => {
        f.prop = { interval: { from: '', to: '' } }
        f.active = false
      })
      this.$store.commit('ODD_SET_EVENTS_FILTER', ['time', this.filters[0]])
      this.$store.commit('ODD_SET_EVENTS_FILTER', ['interval', this.filters[1]])
    },
    setFilter(val) {
      this.$store.commit('ODD_SET_EVENTS_FILTER', ['category', val])
    },
    isActive(val) {
      return this.categoryFilter === val
    },
    updateInterval(filter) {
      const payload = cloneDeep(filter)
      const index = this.filters.findIndex(f => f.id === filter.id)

      this.filters[index].prop = payload.prop
      this.filters[index].active = payload.active
      this.$store.commit('ODD_SET_EVENTS_FILTER', [filter.id === 'time' ? 'time' : 'interval', payload])
    }
  }
}
</script>

<style lang="scss">
.object-filter {
  position: relative;
  display: grid;
  grid-gap: 0.25rem;
  border-bottom: 1px solid var(--dividers_low_contrast);
  padding-bottom: 0.25rem;

  .r-button.secondary {
    padding: 0.25rem 0.5rem;
    height: 1.75rem;
  }

  &-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-interval {
    overflow: hidden;
    width: 244px;

    .r-filter-set {
      display: grid;
      grid-gap: 0.25rem;
      padding: 0;

      .filter-item {
        margin: 0;
      }
    }
  }
}
</style>
