export const vehicleModel = [
  {
    model: 'reg_number',
    required: true,
    title: 'Номер ТС',
    type: 'input'
  },
  {
    model: 'driver_full_name',
    title: 'ФИО водителя',
    type: 'input'
  },
  {
    model: 'driver_phone',
    title: 'Телефон водителя',
    type: 'input'
  },
  {
    model: 'model_id',
    title: 'Марка, модель транспортного средства',
    type: 'select',
    filterable: true,
    select: 'vehicle_models'
  },
  {
    model: 'vehicle_type_id',
    filterable: true,
    title: 'Вид ТС',
    type: 'select',
    select: 'vehicle_types'
  },
  {
    model: 'main_contractor_id',
    title: 'Наименование подрядной / субподрядной организации',
    type: 'select',
    select: 'main_contractor_id',
    filterable: true
  }
]

export const related = {
  vehicle_types: {
    id: 1,
    urlName: 'vehicleTypes',
    data: [],
    config: { only: ['name', 'id'] }
  },
  main_contractor_id: {
    id: 2,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_models: {
    id: 3,
    urlName: 'vehicleModels',
    data: [],
    config: { only: ['name', 'id'] }
  }
}
