<template>
  <div class="interval-filter">
    <div class="popper-filter__content">
      <div class="interval-filter__item">
        <r-text type="caption">
          {{ $t(`interval-filter:start`) }}
        </r-text>
        <el-time-picker
          v-model="interval.from"
          :picker-options="timeSelectableRange"
          format="HH:mm:ss"
          placeholder="Arbitrary time"
        />
      </div>

      <div class="interval-filter__item">
        <r-text type="caption">
          {{ $t(`interval-filter:end`) }}
        </r-text>
        <el-time-picker
          v-model="interval.to"
          :picker-options="timeSelectableRange"
          format="HH:mm:ss"
          placeholder="Arbitrary time"
        />
      </div>
    </div>

    <div class="interval-filter__controls popper-filter__controls">
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t(`interval-filter:accept`) }}
      </r-button>
      <r-button
        v-if="!noClearable"
        simple
        :disabled="isResetDisabled"
        @click="resetFilter"
      >
        {{ $t(`interval-filter:reset`) }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    id: {
      type: String,
      required: true
    },
    noClearable: {
      type: Boolean,
      default: false
    },
    fastValues: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      ...this.filterValue,
      timeSelectableRange: {
        selectableRange: '00:00:00 - 23:59:59'
      }
    }
  },
  computed: {
    isResetDisabled() {
      const { to, from } = this.interval
      return !(from || to)
    }
  },
  created() {
    console.log(this.interval.to)
  },
  methods: {
    resetFilter(resetAll) {
      this.interval.to = null
      this.interval.from = null
      this.prepareData(resetAll)
    },
    prepareData(resetAll) {
      const { interval, id } = this

      if (this.pickerType === 'time') {
        if (interval.to) {
          interval.to = this.$ritmDate.toIso(interval.to)
        }

        if (interval.from) {
          interval.from = this.$ritmDate.toIso(interval.from)
        }
      }

      const data = {
        type: 'time-filter',
        prop: { interval },
        id: id,
        active: !!(interval.from || interval.to)
      }
      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.interval-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  &__item {
    display: grid;
    grid-gap: 0.25rem;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<i18n>
{
  "ru": {
    "interval-filter:start": "Начало",
    "interval-filter:end": "Окончание",
    "interval-filter:accept": "Применить",
    "interval-filter:reset": "Сброс"
  },
  "en": {
    "interval-filter:start": "Start",
    "interval-filter:end": "End",
    "interval-filter:accept": "Accept",
    "interval-filter:reset": "Reset"
  }
}
</i18n>
