<template>
  <r-modal-card-block
    v-loading="loading"
    main
    class="cargo-block"
    title="Информация о грузах"
  >
    <div
      v-if="isEditing"
      class="cargoes-management"
    >
      <div
        v-if="cargoesIsLoaded"
        class="r-modal-card__input-field cargoes-management__select"
      >
        <r-text color-type="secondary">
          Выберите нужные грузы
        </r-text>
        <el-select
          v-model="cargoInfo.idArray"
          class="r-select"
          placeholder="Выберите груз"
          filterable
          multiple
          @change="cargoesHasChanges"
        >
          <el-option
            v-for="item in allCargoes"
            :key="item.id"
            :label="`№ упак: ${item.packing_list_no || '—'} | ${item.name}`"
            :value="item.id"
          />
        </el-select>
      </div>
      <div
        v-if="cargoesChanged"
        class="cargo-block__notify"
      >
        <r-text>
          Для конфигурирования новых привязанных грузов, сохраните текущие
          изменения
        </r-text>
      </div>
      <r-block
        v-if="!cargoesIsLoaded"
        space-between
        no-padding
        col
      >
        <r-button @click="loadAllCargoes">
          Управление привязкой грузов
        </r-button>
        <r-block
          v-if="relevanceCargoes?.length > 1"
          no-padding
          col
        >
          <r-text>
            Мультиредактирование грузов
          </r-text>
          <el-switch
            v-model="cargoMultiEdit"
            class="r-switch"
            @change="toggleMulti"
          />
        </r-block>
      </r-block>
    </div>
    <div class="cargo-block__packing-select">
      <r-text color-type="secondary">
        Информация по текущему грузу
      </r-text>
      <div
        v-if="potItem.cargoes[0]"
        class="cargo-relevance"
      >
        <r-tabs
          v-if="!isEditing"
          v-model="cargoTab"
        >
          <r-tab
            v-for="tab in tabs"
            :id="tab.id"
            :key="tab.id"
            :name="tab.name"
          />
        </r-tabs>
        <el-select
          v-if="!cargoMultiEdit"
          :key="keyHelper1"
          v-model="cargoInfo.current"
          class="r-select"
          placeholder="Выберите груз"
          filterable
          @change="setCurrentCargo"
        >
          <el-option
            v-for="item in relevanceCargoes"
            :key="item.id"
            :label="`№ упак: ${item.packing_list_no || '—'} | ${item.name}`"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-else
          :key="keyHelper2"
          v-model="multiEditCargoes"
          placeholder="Выберите грузы"
          class="r-select"
          filterable
          multiple
          @change="setMultiCargoIds"
        >
          <el-option
            v-for="item in relevanceCargoes"
            :key="item.id"
            :label="`№ упак: ${item.packing_list_no || '—'} | ${item.name}`"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div
      v-if="!hasCargo"
      class="cargo-block__notify"
    >
      <r-text>
        К этому ТС не привязаны
        {{ getCargoStatus() }}
        грузы. Привяжите его, чтобы увидеть информацию и управлять разгрузкой.
      </r-text>
    </div>
    <r-modal-card-block
      icon="users"
      title="Контактные лица"
    >
      <attributes-list
        :key="keyHelper1"
        :model="model.filter(m => m.block === 'contacts')"
        :related="related"
        :disabled="!hasCargo"
        :source="currentSource"
        :is-editing="isEditing"
        @hasChanges="cargoesHasChanges"
      />
    </r-modal-card-block>
    <r-modal-card-block
      icon="cargo"
      title="Груз"
      transparent
    >
      <attributes-list
        :key="keyHelper1"
        :model="model.filter(m => m.block === 'cargo')"
        :related="related"
        :disabled="!hasCargo"
        :source="currentSource"
        :is-editing="isEditing"
        @hasChanges="cargoesHasChanges"
      />
    </r-modal-card-block>
  </r-modal-card-block>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'
import rModalCardBlock from '@/components/globals/r-modal-elements/r-modal-card-block'
import { generateUUID } from '@/utils'

export default {
  components: {
    rModalCardBlock,
    attributesList
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    cargoInfo: {
      type: Object,
      required: true
    },
    potItem: {
      type: Object,
      required: true
    },
    clone: {
      type: Object,
      required: true
    },
    related: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: '',
      currentSource: null,
      cargoTab: 'actual',
      tabs: [
        { id: 'actual', name: 'Актуальные' },
        { id: 'history', name: 'История' }
      ],
      cargoesIsLoaded: false,
      cargoesChanged: false,
      cargoMultiEdit: false,
      keyHelper1: 1,
      keyHelper2: 2,
      allCargoes: [],
      multiEditCargoes: []
    }
  },
  computed: {
    relevanceCargoes() {
      return this.clone.cargoes.filter(({ receiving_date_fact }) => {
        return this.cargoTab === 'actual' ? !receiving_date_fact : !!receiving_date_fact
      }
      )
    },
    hasCargo() {
      return this.relevanceCargoes[0]?.id
    },
    cargoSouceId() {
      return this.$store.state.pot1.cargoId
    },
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    }
  },
  watch: {
    hasCargo: {
      handler: function(cargoId) {
        this.cargoInfo.current = cargoId
        this.setCurrentCargo()
      },
      immediate: true
    },
    cargoInfo: {
      handler: function() {
        this.setCurrentCargo()
      },
      deep: true
    },
    isEditing(v) {
      if (!v) {
        this.cargoesIsLoaded = false
        this.cargoesChanged = false
        this.$emit('cargoesChanges', false)
      }
      this.cargoMultiEdit = false
      this.setMultiCargoIds([])
      this.setCurrentCargo()
      this.updateKeyHelpers()
    }
  },
  beforeDestroy() {
    this.$emit('toggle-multi-cargo', false)
    this.$emit('multi-cargo-object-update', null)
    this.setMultiCargoIds([])
  },
  methods: {
    updateKeyHelpers() {
      this.keyHelper1 = generateUUID()
      this.keyHelper2 = generateUUID()
    },
    setCurrentCargo() {
      if (!this.cargoMultiEdit) {
        this.currentSource = this.potItem.cargoes.find(c => c.id === this.cargoInfo.current && this.hasCargo) || {}
      } else {
        this.currentSource = {}
      }
    },
    toggleMulti(val) {
      this.multiEditCargoes = [this.cargoInfo.current]
      this.updateKeyHelpers()
      this.$emit('toggle-multi-cargo', val)
      this.$emit('set-multi-cargo-ids', this.multiEditCargoes)

      this.setCurrentCargo()
    },
    setMultiCargoIds(val) {
      this.$emit('set-multi-cargo-ids', val)
    },
    getCargoStatus() {
      return this.potItem.cargoes[0]
        ? this.cargoTab === 'actual'
          ? 'актуальные'
          : 'доставленные'
        : ''
    },
    cargoesHasChanges() {
      this.cargoesChanged = true
      this.$emit('cargoesChanges', true)
      this.$emit('multi-cargo-object-update', this.currentSource)
    },
    async loadAllCargoes() {
      try {
        this.loading = true
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.cargoSouceId}?config={"include":{"cargoes_assigment":{}}}`
        })
        this.allCargoes = Object.entries(data).reduce((a, c) => {
          const { id, packing_list_no, name } = c[1]
          a.push({ id, packing_list_no, name })
          return a
        }, [])
        this.cargoesIsLoaded = true
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.cargo-block {
  &__packing-select {
    display: grid;
    grid-gap: 0.5rem;

    .cargo-relevance {
      display: grid;
      align-items: center;
      grid-gap: 0.5rem;
      grid-auto-flow: column;
    }
  }

  .cargoes-management {
    display: grid;
    grid-gap: 1rem;
  }

  &__notify {
    background-color: var(--field_error_bg);
    border-radius: var(--border-radius);
    padding: 0.5rem;
  }
}
</style>
