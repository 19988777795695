<template>
  <div class="map-style point">
    <div class="map-style__block">
      <point-icon-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
      />
    </div>
    <div
      v-if="!layerStyle.icon.enabled"
      class="map-style__block"
    >
      <classes-color-picker
        :id="id"
        :layer-style="layerStyle.style"
        :controllers="controllers"
        property="circle"
        title="Цвет"
        @change-property="changeProperty"
        @change-classes="changeClasses"
        @change-strings="changeStrings"
      />
    </div>
    <div
      v-if="!layerStyle.icon.enabled"
      class="map-style__block"
    >
      <classes-slider-picker
        :id="id"
        :layer-style="layerStyle.style"
        :controllers="controllers"
        property="circle-radius"
        title="Радиус"
        @change-property="changeProperty"
        @change-range="changeRange"
      />
    </div>
    <div
      v-if="!layerStyle.icon.enabled"
      class="map-style__block"
    >
      <point-outline-picker
        :id="id"
        :layer-style="layerStyle.style"
        :controllers="controllers"
        property="circle"
        title="Обводка"
        @change-property="changeProperty"
      />
    </div>
    <r-block no-padding>
      <popup-fields-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
        title="Информация"
      />
      <popup-click-fields-picker
        :id="id"
        :layer-style="layerStyle"
        :controllers="controllers"
      />
    </r-block>
  </div>
</template>

<script>
import PointIconPicker from '../input-sources/point-icon-picker'
import ClassesColorPicker from '../input-sources/classes-color-picker'
import ClassesSliderPicker from '../input-sources/classes-slider-picker'
import PointOutlinePicker from '../input-sources/point-outline-picker'
import PopupFieldsPicker from '../input-sources/popup-fields-picker'
import PopupClickFieldsPicker from '../input-sources/popup-click-fields-picker'

export default {
  components: {
    PointIconPicker,
    ClassesColorPicker,
    ClassesSliderPicker,
    PointOutlinePicker,
    PopupFieldsPicker,
    PopupClickFieldsPicker
  },
  props: {
    id: {
      type: String,
      required: true
    },
    layerStyle: {
      type: Object,
      required: true
    },
    controllers: {
      type: Object,
      required: true
    }
  },
  created() {
    if (!this.layerStyle.icon) {
      this.$set(this.layerStyle, 'icon', {
        enabled: false,
        name: '1.5_Russian_road_sign.svg',
        size: 16
      })
    }
  },
  methods: {
    changeProperty(payload) {
      this.$emit('change-property', payload)
    },
    changeClasses(payload) {
      this.$emit('change-classes', payload)
    },
    changeStrings(payload) {
      this.$emit('change-strings', payload)
    },
    changeRange(payload) {
      this.$emit('change-range', payload)
    }
  }
}
</script>

<style lang="scss">
.map.style-picker {
  &__title {
    vertical-align: middle;
    font-weight: bold;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 4px;
      height: 18px;
      border-radius: 2px;
      background-color: var(--accent_primary) !important;
      margin-right: 8px;
      margin-top: -1px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 24px;

    i {
      font-size: 14px;
    }
  }
}
.simple-settings-block {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
    line-height: 24px;

    i {
      font-size: 14px;
    }
  }

  &__container {
    padding-left: 15px;

    .el-icon-loading {
      margin-right: 8px;
    }
  }
}
</style>
