import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import baselayers from '@/config/baselayers'

const state = {
  baselayers,
  extent: {},
  styleConfig: {},
  requestedFields: null,
  objectFieldsConfigs: null,
  isGraphLoading: false,
  activeLayers: [],
  initialActiveLayers: [],
  heatmapLayers: [],
  loadingLayers: [],
  flyToGeom: null,
  zonesList: {},
  matricesList: {},
  activeCardFeature: null,
  iconsContext: null,
  historyFeatures: null,
  historyHoveredIndex: -1,
  updateGraph: false,
  updatePopupLayer: false
}

export const mutations = {
  MAP_SET_FIELD(state, [field, value]) {
    if (field.includes('.')) {
      const [first, second] = field.split('.')

      state[first][second] = value
    } else {
      state[field] = value
    }
  },
  MAP_SET_STYLE_FIELD(state, [field, value]) {
    if (field.includes('.')) {
      const [first, second] = field.split('.')

      state.styleConfig[first][second] = value
    } else {
      state.styleConfig[field] = value
    }
  },
  SET_GRAPH_LOADING_STATUS(state, value) {
    state.isGraphLoading = value
  },
  SET_ICONS_CONTEXT(state, context) {
    state.iconsContext = context
  },
  TOGGLE_ACTIVE_LAYER(state, id) {
    const index = state.activeLayers.indexOf(id)
    if (index === -1) state.activeLayers.push(id)
    else state.activeLayers.splice(index, 1)
  },
  CLEAR_ACTIVE_LAYERS(state) {
    state.activeLayers = []
  },
  SET_INITIAL_ACTIVE_LAYERS(state, layers) {
    state.initialActiveLayers = layers
  },
  TOGGLE_LOADING_LAYER(state, id) {
    const index = state.loadingLayers.indexOf(id)
    if (index === -1) state.loadingLayers.push(id)
    else state.loadingLayers.splice(index, 1)
  },
  SET_LAYER_EXTENT(state, { id, extent }) {
    state.extent[id] = extent
  },
  SET_MAP_STYLE_CONFIG(state, { fields, config, fieldsConfigs }) {
    state.requestedFields = fields
    state.styleConfig = config
    state.objectFieldsConfigs = fieldsConfigs
  },
  SET_FIELD_CLASSES_STYLE(state, { id, field, config }) {
    state.styleConfig[id].classesStyle[field] = cloneDeep(config)
  },
  SET_FIELD_STRINGS_STYLE(state, { id, field, config }) {
    state.styleConfig[id].stringsStyle[field] = cloneDeep(config)
  },
  SET_FILL_EXTRUSION_STYLE(state, { id, config }) {
    const { paint } = state.styleConfig[id].fillExtrusion
    state.styleConfig[id].fillExtrusion = cloneDeep({ ...config, paint })
  },
  SET_DIAGRAM_CLASSES(state, { id, config }) {
    const { diagram } = state.styleConfig[id]
    state.styleConfig[id].diagram = { ...diagram, ...config }
  },
  SET_HEATMAP_STYLE(state, { id, config, field }) {
    const current = state.styleConfig[id][field]
    state.styleConfig[id][field] = { ...current, ...config }
  },
  SET_FLY_TO_GEOM(state, value) {
    state.flyToGeom = value
  },
  SET_ZONES_LIST(state, { layerId, list }) {
    Vue.set(state.zonesList, layerId, list)
  },
  SET_MATRICES_LIST(state, { layerId, list }) {
    Vue.set(state.matricesList, layerId, list)
  },
  SET_MAP_ACTIVE_CARD_FEATURE(state, feature) {
    Vue.set(state, 'activeCardFeature', feature)
  },
  SET_HISTORY_FEATURES(state, features) {
    state.historyFeatures = features
  },
  SET_HISTORY_HOVERED_FEATURE(state, index) {
    state.historyHoveredIndex = index
  }
}

export default {
  state,
  mutations
}
