<template>
  <div class="an-report-info">
    <div class="an-report-info-col">
      <r-text type="caption">
        Дата
      </r-text>
      <r-text>
        {{ date }}
      </r-text>
    </div>
    <div
      v-for="service in servicesData"
      :key="service.id"
      class="an-report-info-col"
    >
      <template v-if="!service.hide && !['bdd_report', 'crossings_geozones'].includes(reportName)">
        <r-text
          type="caption"
        >
          {{ service.name }}
        </r-text>
        <r-text>
          {{ getServiceValueName(service) }}
        </r-text>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    reportName() {
      return this.$store.state.analytics.reportName || null
    },
    datetime() {
      return this.$store.state.analytics.selectedDate || null
    },
    date() {
      if (this.datetime?.from) {
        return this.$ritmDate.toFormat(this.datetime.from, 'MMMM YYYY')
      }
      if (this.datetime?.date) {
        return this.$ritmDate.toFormat(this.datetime.date, 'DD-MM-YYYY')
      }
      if (this.datetime?.from_dt && this.datetime?.to_dt) {
        const { from_dt, to_dt } = this.datetime
        const dateFrom = this.$ritmDate.toFormat(decodeURIComponent(from_dt), 'DD.MM.YYYY • HH:mm')
        const dateTo = this.$ritmDate.toFormat(decodeURIComponent(to_dt), 'DD.MM.YYYY • HH:mm')

        return `с ${dateFrom} - по ${dateTo}`
      }

      return '-'
    },
    servicesData() {
      if (!this.$store.state.analytics.servicesData) return {}

      return this.$store.state.analytics.servicesData || {}
    }
  },
  methods: {
    getServiceValueName(service) {
      if (service.key) {
        return service.value
      } else {
        const data = this.servicesData[service.name]?.data
        let result = '-'
        const vals = Array.isArray(service.value) ? service.value : [service.value]
        if (Array.isArray(data)) {
          result = vals.map((e) => data.find((n) => n.id === e)?.[service.serviceField])
            .join(', ')
        } else {
          result = this.servicesData[service.name]?.data[service.value]?.[service.serviceField] || '-'
        }
        return result
      }
    }
  }
}
</script>

<style lang="scss">
.an-report-info {
  position: relative;
  display: flex;
  padding: 0 8px;
  margin-left: 8px;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
