<template>
  <div class="mt-card-actions">
    <r-button
      icon="focus-zone"
      @click="flyTo"
    >
      Найти на карте
    </r-button>
    <attributes-editor
      :id="id"
      :source_id="etranSourceId"
      @updated="$emit('updated')"
    />
  </div>
</template>

<script>
import attributesEditor from '@/components/attributes-editor/attributes-editor'

export default {
  components: { attributesEditor },
  props: {
    location: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      carsListId: this.$store.state.railwayControl.carsListId || null,
      etranSourceId: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099'
    }
  },
  methods: {
    flyTo() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'flyToGeom',
        value: this.location
      })
    },
    closeCard() {
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'trackDataId',
        value: null
      })
      this.$store.commit('MT_TMC_SET_FIELD', {
        field: 'cardTrack',
        value: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-card-actions {
  display: flex;

  .r-button {
    &:first-child {
      flex: 1;
    }

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
</style>
