<template>
  <div
    :style="
      `display: ${settings.display};top: ${settings.top}px; left: ${
        settings.left
      }px`
    "
    class="map-comp-popup"
  >
    <ul class="map-comp-popup__list">
      <li
        v-for="item in settings.values"
        :key="item.key"
        class="map-comp-popup__item"
      >
        <div class="map-comp-popup__key">
          {{ item.key }}
        </div>
        <div class="map-comp-popup__value">
          {{ getValue(item.value) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: () => null
    }
  },
  created() {
    console.log(this.settings)
  },
  methods: {
    getValue(value) {
      return value === 0 ? value : value || 'Не указано'
    }
  }
}
</script>

<style lang="scss">
.map-comp-popup {
  position: absolute;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_surface) !important;
  border-color: var(--dividers_low_contrast) !important;
  color: var(--text_primary) !important;
  min-width: 120px;
  padding: 0.25rem;
  font-size: 12px;
  z-index: 10;

  &__item {
    display: grid;
    grid-gap: 0.25rem;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  &__value {
    font-weight: bold;
  }
}
</style>
