<template>
  <div class="mt-card-tabs">
    <r-tabs
      v-model="activeTab"
      metro
      indent
    >
      <r-tab
        v-for="item in tabs"
        :id="item.id"
        :key="item.id"
        :name="item.title"
      />
    </r-tabs>
    <div class="mt-card-tabs__comp">
      <component
        :is="activeTab"
        :id="id"
        :info="info"
        :source_id="source_id"
      />
    </div>
  </div>
</template>

<script>
import MtTrack from './tabs/track'
import Geozones from './tabs/geozones/geozones'
import Events from './tabs/events'
import Telematics from './tabs/telematics/telematics'
import Journal from './tabs/journal/journal'
import Route from './tabs/routes.vue'
import FilesComments from '@/components/files-comments/files-comments'

const source_id = 'f09e59fb-ee58-482c-8b0f-9ac520cf2099'

const tabs = [
  {
    id: 'mt-track',
    title: 'Трек'
  },
  {
    id: 'geozones',
    title: 'Геозоны'
  },
  // {
  //   id: 'events',
  //   title: 'События'
  // },
  {
    id: 'journal',
    title: 'Журнал событий'
  },
  {
    id: 'files-comments',
    title: 'Файлы и комментарии'
  },
  {
    id: 'telematics',
    title: 'Телематика'
  },
  {
    id: 'route',
    title: 'Маршруты'
  }
]

export default {
  components: {
    MtTrack,
    Geozones,
    Events,
    Telematics,
    Journal,
    Route,
    FilesComments
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'mt-track',
      tabs,
      source_id
    }
  },
  computed: {
    id() {
      return this.$store.state.monitoring.cardId
    }
  },
  watch: {
    activeTab() {
      this.$store.commit('SET_MT_FIELD', {
        field: 'telematicsData',
        value: null
      })
    }
  }
}
</script>

<style lang="scss">
.mt-card-tabs {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-gap: 0.5rem;
  align-content: flex-start;
  position: relative;

  &__comp {
    padding: 0.25rem 0.5rem;
    overflow: auto;
    height: 100%;
  }
}
</style>
