import { ritmDate } from '@/utils'

const eventTypes = {
  // transport
  cross_geozone_out: 'Выход из геозоны',
  cross_geozone_in: 'Вход в геозону',
  deviation_from_route: 'Отклонение от маршрута',
  return_to_route: 'Выход на маршрут',
  over_speed: 'Превышение скорости по GPS',
  geozone_over_speed: 'Превышение скорости в геозоне',
  move: 'Движение',
  stop: 'Остановка',
  status_changed: 'Изменение статуса ретрансляции',
  passing_road: 'Проезд ТС по дорожным камерам',
  // cameras
  1: 'Въезд ТС на территорию',
  2: 'Выезд ТС с территории',
  4: 'Въезд ТС на ПОТ',
  5: 'Выезд ТС с ПОТ',
  6: 'Проезд ТС по дорожным камерам',
  9: 'Проезд ТС ФВФ',
  10: 'Превышение скорости по ФВФ',
  11: 'ФВФ: Выезд на встречную полосу',
  3: 'Нарушение геозоны',
  41: 'Проезд ТС по видеоаналитике',
  42: 'Направление транспорта',
  43: 'Пересечение геозоны',
  44: 'Фиксация затора',
  45: 'Фиксация ДТП',
  61: 'Нарушение временого режима',
  8: 'Опасное маневрирование',
  // system
  import_data: 'Импорт данных',
  RzdDataImporter: 'Импорт данных РЖД',
  RegistrationListImporter: 'Импорт данных Листа ретрансляции БСМТС',
  RetranslationListImporter: 'Импорт данных Листа ретрансляции ТС с ТМЦ',
  EtranDataImporter: 'Импорт данных ЭТРАН',
  TiffImporter: 'Импорт Tiff-файла',
  MailSender: 'Отправка почты',
  LinkCurSpeedWriter: 'Расчет скоростей движения на линках по трэкам',
  noverspeed: 'Превышение скорости по ФВФ',
  nwrongdirection: 'ФВФ: Выезд на встречную полосу',
  DataXlsExport: 'Экспорт данных ТМЦ',
  ToApplicationImporter: 'Импорт заявки на ТО'
}

export const getEventType = type => (eventTypes[type] || type)

const eventColors = {
  // transport
  cross_geozone_in: 'default',
  cross_geozone_out: 'default',
  deviation_from_route: 'warning',
  return_to_route: 'warning',
  geozone_over_speed: 'error',
  over_speed: 'error',
  move: 'default',
  stop: 'default',
  status_changed: 'warning',
  // system
  import_data: 'default',
  RzdDataImporter: 'default',
  RegistrationListImporter: 'default',
  RetranslationListImporter: 'default',
  EtranDataImporter: 'default',
  TiffImporter: 'default',
  MailSender: 'default',
  LinkCurSpeedWriter: 'default',
  DataXlsExport: 'default',
  ToApplicationImporter: 'default',
  // cameras
  45: 'error',
  44: 'error',
  41: 'default',
  noverspeed: 'error',
  nwrongdirection: 'error',
  passing: 'default',
  pot_entry: 'default',
  passing_road: 'default',
  pot_exit: 'default',
  kpp_entry: 'default',
  kpp_exit: 'default',
  10: 'error',
  11: 'error',
  6: 'default',
  4: 'default',
  5: 'default',
  1: 'default',
  2: 'default'
}

export const getEventColor = type => (type ? eventColors[type] : 'default')

const getTimeField = datetime => {
  return datetime ? ritmDate.toFormat(datetime, 'DD.MM.YYYY • HH:mm') : 'Не определено'
}

export const dataFunctions = {
  car_event: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.event_time),
      reg_number: e.reg_number,
      model: e.vehicle_model_name,
      vehicle_type: e.vehicle_type_name,
      vehicle_type_group: e.vehicle_type_group_name || '—',
      contractor: e.contractor_name || '—',
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      speed: e.event_attrs?.speed || '—',
      speed_limit: e.event_attrs?.speed_limit || '—',
      no: i + 1
    })),
  system_event: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.end_time),
      status: e.success ? 'Выполнена успешно' : 'Ошибка',
      error_message: e.error_message,
      no: i + 1
    })),
  gazprom: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.time),
      vehicle_type_group: e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—',
      contractor: e.vehicle?.contractor?.name || '—',
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      recognizer_name: e.camera_source || 'Не определено',
      checkpoint: e.vehicle?.checkpoint?.no || '—',
      no: i + 1
    })),
  videophoto: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.time),
      model: e.vehicle?.model?.name,
      vehicle_type_group: e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—',
      contractor: e.vehicle?.contractor?.name || '—',
      subprojects: e.vehicle?.subprojects?.map((p) => p?.name).join(',') || '—',
      project_label: e.vehicle?.project_label,
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      recognizer_name: e.camera_source || 'Не определено',
      permit_number: e.applications_permit_number || '—',
      no: i + 1
    })),
  videoanalytics: events =>
    events.map((e, i) => ({
      ...e,
      event_type: getEventType(e.action_type) || '—',
      event_time: getTimeField(e.time) || '—',
      vehicle_type: e.vehicle?.vehicle_type?.name || '—',
      vehicle_type_group: e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—',
      contractor: e.vehicle?.contractor?.name || '—',
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      no: i + 1
    })),
  nipigaz: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.time),
      vehicle_type: e.vehicle?.vehicle_type?.name || '—',
      vehicle_type_group: e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—',
      contractor: e.vehicle?.contractor?.name || '—',
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      recognizer_name: e.camera_source || 'Не определено',
      no: i + 1
    })),
  nipigaz_pot: events =>
    events.map((e, i) => ({
      ...e,
      event_time: getTimeField(e.time),
      vehicle_type_group: e.vehicle?.vehicle_type?.vehicle_type_group?.name || '—',
      contractor: e.vehicle?.contractor?.name || '—',
      sub_contractor: e.vehicle?.sub_contractor?.name || '—',
      recognizer_name: e.camera_source || 'Не определено',
      no: i + 1
    }))
}
