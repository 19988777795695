<template>
  <div class="mt-geozones">
    <el-popover
      v-model="popoverVisible"
      placement="bottom"
      width="408"
      trigger="click"
    >
      <r-button
        slot="reference"
        icon="list"
        uppercase
        dropdown
        bold
      >
        Выбрать маршруты для ТС
      </r-button>
      <div class="mt-geozones__select-geozones select-geozones">
        <r-block no-padding>
          <r-block
            no-padding
            col
            space-between
          >
            <r-title type="subtitle-2">
              Маршруты
            </r-title>
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAll"
            >
              Выбрать все
            </el-checkbox>
          </r-block>
          <r-search-input
            placeholder="Поиск по названию"
            :filter-text="filterText"
            @change="filterText = $event"
          />
        </r-block>
        <div class="select-geozones-content">
          <el-checkbox-group
            v-model="checkedRoutes"
            class="select-geozones__group"
            @change="handleRoutes"
          >
            <el-checkbox
              v-for="geozone in initialList"
              :key="geozone.id"
              class="select-geozones__item"
              :label="geozone.id"
            >
              <r-text>
                {{ geozone.name || '-' }}
              </r-text>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-pagination
          v-if="hasPagination"
          class="r-pagination"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalObjects"
          @current-change="currentPage = $event"
        />
        <r-button
          type="primary"
          @click="applyRoutes"
        >
          Применить
        </r-button>
      </div>
    </el-popover>
    <r-text
      v-if="!tableData.length"
      type="caption"
    >
      Нет связанных геозон
    </r-text>
    <r-table-list
      v-else
      :data="tableData"
      :fields-names="fieldsExplanation"
    />
  </div>
</template>

<script>
export default {
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fieldsExplanation: {
        // 'action-zone-visibility': 'Действия',
        'vehicle-access-geozones': 'Маршруты'
      },
      checkedRoutes: [],
      isIndeterminate: false,
      checkAll: false,
      allRoutes: [],
      vehicleRoutes: [],
      vehicleRoutesIds: [],
      popoverVisible: false,
      filterText: '',
      currentPage: 1,
      pageSize: 25
    }
  },
  computed: {
    tableData() {
      return this.preparedData?.map(g => ({
        'vehicle-access-geozones': g.name || '-'
      }))
    },
    preparedData() {
      return this.allRoutes.filter(g =>
        this.vehicleRoutesIds.includes(g.id)
      )
    },
    filteredRoutes() {
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) return this.allRoutes

      return this.allRoutes?.filter(g => g.name?.toLowerCase()?.includes(query))
    },
    totalObjects() {
      return this.filteredRoutes?.length || 0
    },
    initialList() {
      if (!this.hasPagination) {
        return this.filteredRoutes
      } else {
        const start =
                this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.pageSize
        const end =
                this.currentPage === 1
                  ? this.totalObject < this.pageSize
                    ? this.totalObject
                    : this.currentPage * this.pageSize
                  : this.currentPage * this.pageSize > this.totalObject
                    ? this.totalObject
                    : this.currentPage * this.pageSize

        return this.filteredRoutes?.slice(start, end) || []
      }
    },
    hasPagination() {
      return this.allRoutes.length > 25
    }
  },
  watch: {
    id() {
      this.getVehicleRoutes()
    }
  },
  async mounted() {
    await this.loadAllRoutes()
    await this.getVehicleRoutes()
  },
  methods: {
    async loadAllRoutes() {
      const getConfig = { only: ['id', 'name'] }
      const request = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/telemetry.user_routes?config=${JSON.stringify(getConfig)}`
      })
      this.allRoutes = Object.values(request.data) || []
    },
    async getVehicleRoutes() {
      const config = {
        only: ['id', 'route_id'],
        where: [{ field: 'vehicle_id', value: this.id, op: '=' }]
      }
      const request = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/telemetry.vehicle_routes?config=${JSON.stringify(config)}`
      })
      this.vehicleRoutes = Object.values(request.data)
      this.vehicleRoutesIds = this.vehicleRoutes.map(g => g.route_id)
      this.checkedRoutes = this.preparedData.map(g => g.id)
    },
    async applyRoutes() {
      await this.setRoutes()
      this.getVehicleRoutes()
      this.popoverVisible = false
    },
    async setRoutes() {
      await this.$store.dispatch('POST_REQUEST', {
        url: 'vehicle_routes',
        data: {
          vehicle_id: this.id,
          vehicle_route_ids: this.checkedRoutes
        }
      })
    },
    handleRoutes(v) {
      this.checkedRoutes = v
      const checkedCount = this.checkedRoutes.length
      this.checkAll = checkedCount === this.allRoutes.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.allRoutes.length
    },
    handleCheckAll(v) {
      this.checkedRoutes = v ? this.allRoutes.map(i => i.id) : []
      this.isIndeterminate = false
    }
  }
}
</script>

<style lang='scss'>
.select-geozones {
  display: grid;
  grid-gap: 0.5rem;

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    max-height: 60vh;
    min-height: 370px;
    overflow: auto;
    display: grid;
    grid-gap: 0.5rem;
    align-items: start;
  }

  .el-checkbox-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    grid-gap: 0.5rem;

    & > label {
      display: flex;
      align-items: center;
    }
  }
}

.mt-geozones {
  width: 100%;
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-gap: 0.5rem;
  grid-auto-flow: row;
  align-content: start;

  .el-table {
    &::before {
      height: 0;
    }

    .r-button {
      padding: 0 !important;
      background-color: transparent !important;

      & > span {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 1fr auto;
        justify-items: start;
      }
    }
  }
}
</style>
