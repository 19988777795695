import vue from 'vue'

const state = {
  etran_source_id: '42651eb5-0954-4687-a9d6-23872b98b009',
  vehicles_source_id: 'f09e59fb-ee58-482c-8b0f-9ac520cf2099',
  osrm_id: 'afda123e-b204-4306-ba30-2f9c5c0843b6',
  etranObjectFields: null,
  vehiclesObjectFields: null,
  cargoesObjectFields: null,
  categoryId: null,
  flyToGeom: null,
  cardId: null,
  vehiclesCount: 0,
  mainTrackGeom: null,
  secondTrackGeom: null,
  trackDataId: null,
  trackColors: {
    main: '#2E93FE',
    second: '#E87835'
  },
  isVehicleListOpened: false,
  telematicsData: null,
  etran: [],
  vehicles: [],
  noGeomVehicles: [],
  noGeomEtran: [],
  vehiclesLoading: false,
  filters: [],
  filterCategories: {
    project: [],
    type_group_id: [],
    main_contractor_id: []
  },
  statusFilter: {
    type: 'retranslation_status',
    title: 'Статус',
    items: [
      {
        id: 'green',
        count: 0,
        name: 'На связи',
        active: true,
        values: ['online']
      },
      {
        id: 'yellow',
        count: 0,
        name: 'Нет связи (отметка более 5 мин назад)',
        active: true,
        values: [
          'without_coords_halfline',
          'with_coords_halfline',
          'with_coords_offline'
        ]
      },
      {
        id: 'blue',
        count: 0,
        name: 'На связи (нет координат более 30 мин)',
        active: true,
        values: ['without_coords_offline']
      },
      {
        id: 'red',
        count: 0,
        name: 'Нет данных',
        active: true,
        values: ['nodata', null]
      }
    ],
    show: true
  },
  statusFilterUpdated: false,
  lastUpdatedVehicleId: null,
  update: {
    etran: false,
    vehicle: false
  },
  fcTrackAgpz: null,
  fcTrackAghk: null
}

const mutations = {
  MT_TMC_SET_FIELD(state, { field, value }) {
    if (field.includes('.')) {
      const [first, second] = field.split('.')

      state[first][second] = value
    } else {
      state[field] = value
    }
  },
  MT_TMC_SET_EXCLUDE_CATEGORIES(state, { type, value }) {
    vue.set(state.filterCategories, type, value)
  },
  MT_TMC_SET_UPDATE_FIELD(state, { field, value }) {
    vue.set(state.update, field, value)
  }
}

export default {
  state,
  mutations
}
