export const related = {
  vehicle_models: {
    id: 1,
    urlName: 'vehicleModels',
    data: [],
    config: { only: ['name', 'id'] }
  },
  vehicle_types: {
    id: 2,
    urlName: 'vehicleTypes',
    data: [],
    config: { only: ['name', 'id'] }
  },
  main_contractors: {
    id: 3,
    urlName: 'mainContractor',
    data: [],
    config: { only: ['name', 'id'] }
  }
}

export const model = [
  {
    model: 'driver_full_name',
    title: 'ФИО водителя',
    type: 'input'
  },
  {
    model: 'model_id',
    title: 'Марка, модель транспортного средства',
    type: 'select',
    select: 'vehicle_models',
    filterable: true
  },
  {
    model: 'vehicle_type_id',
    title: 'Вид ТС',
    type: 'select',
    select: 'vehicle_types',
    filterable: true
  },
  {
    model: 'reg_number',
    title: 'Государственный регистрационный знак',
    placeholder: 'А 123 АА 123',
    required: true,
    type: 'input'
  },
  {
    model: 'driver_phone',
    title: 'Телефон водителя',
    placeholder: '+7 999 876 5432',
    type: 'input'
  },
  {
    model: 'main_contractor_id',
    title: 'Наименование подрядной / субподрядной организации',
    type: 'select',
    select: 'main_contractors',
    filterable: true
  },
  {
    model: 'year',
    title: 'Год выпуска транспортного средства',
    placeholder: 'Введите год',
    type: 'input',
    mask: 'YYYY'
  }
]
