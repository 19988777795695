export const filters = () => ([
  {
    id: 'created_at',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата создания',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'datetime',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Дата фиксации',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'elimination_date',
    source: null,
    initType: 'date-filter',
    type: 'day-filter',
    title: 'Срок устранения',
    prop: { interval: { from: '', to: '' } },
    active: false
  },
  {
    id: 'type_id',
    source: 'type',
    type: 'checkbox-filter',
    title: 'Тип',
    prop: [],
    active: false
  },
  {
    id: 'subproject_id',
    source: 'subproject',
    type: 'checkbox-filter',
    title: 'Подпроект',
    prop: [],
    active: false
  },
  {
    id: 'detection_employee_id',
    source: 'employees',
    type: 'checkbox-filter',
    title: 'ФИО выявившего нарушение',
    prop: [],
    active: false
  },
  {
    id: 'guilty_name',
    type: 'string-filter',
    title: 'ФИО',
    prop: '',
    active: false
  },
  {
    id: 'contractor_id',
    source: 'contractor',
    type: 'checkbox-filter',
    title: 'Подрядчик',
    prop: [],
    active: false
  },
  {
    id: 'subcontractor_id',
    source: 'contractor',
    type: 'checkbox-filter',
    title: 'Субподрядчик',
    prop: [],
    active: false
  },
  {
    id: 'violation_act_no',
    source: null,
    type: 'radio-filter',
    title: 'Наличие акта',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  },
  {
    id: 'eliminated',
    source: null,
    type: 'radio-filter',
    title: 'Устранено',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  },
  {
    id: 'event_id',
    source: null,
    type: 'radio-filter',
    title: 'Импортировано из ОДД',
    prop: [
      { id: 'all', label: 'Все' },
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' }
    ],
    active: false
  }
])
