import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import {
  // addListenerToSocket,
  parseObjectFields,
  pointArrayToGeojson,
  getFieldsAggregation,
  getDatasourceById,
  errorParser,
  getRequestConfig,
  getFirstSymbolId
} from '@/utils'
import { MAP_ICON_SIZE } from '@/config/icons'
import { styleConfigs } from '../configs'
import {
  createHeatmapColorValue,
  getLayerColor,
  getLayerLoader
} from '../helpers'

export class LayerController {
  constructor(map) {
    this.map = map
    this.handlers = {}
    this.clustersHandlers = {}
    this.popupEnterHandlers = {}
    this.popupOutHandlers = {}
    this.clickHandlers = {}
    this.previousTM = {}
    this.layerMousemoveHandlers = {}
    this.layerMouseoutHandlers = {}
  }

  removeLayerById(id) {
    const mapgl = this.map.mapgl

    mapgl.off('moveend', this.handlers[id])
    mapgl.off('click', `${id}_clusters`, this.clustersHandlers[id])

    this.toggleCaptionLayer(id)
    // remove points clusters layer
    if (mapgl.getLayer(`${id}_clusters`)) mapgl.removeLayer(`${id}_clusters`)
    if (mapgl.getLayer(`${id}_clusters_count`)) {
      mapgl.removeLayer(`${id}_clusters_count`)
    }

    // remove polygon borders layer
    if (mapgl.getLayer(`${id}_border`)) mapgl.removeLayer(`${id}_border`)

    // remove matrices
    const mapLayerId = `${id}_matrices`
    const labelsLayerId = `${id}_matrices_labels`
    const circlesLayerId = `${id}_matrices_circles`
    if (mapgl.getLayer(mapLayerId)) mapgl.removeLayer(mapLayerId)
    if (mapgl.getLayer(labelsLayerId)) mapgl.removeLayer(labelsLayerId)
    if (mapgl.getLayer(circlesLayerId)) mapgl.removeLayer(circlesLayerId)
    if (mapgl.getSource(labelsLayerId)) mapgl.removeSource(labelsLayerId)

    // remove additional layers
    if (mapgl.getLayer(`${id}_diagram`)) mapgl.removeLayer(`${id}_diagram`)
    if (mapgl.getLayer(`${id}_diagram_extrusion`)) {
      mapgl.removeLayer(`${id}_diagram_extrusion`)
    }
    if (mapgl.getLayer(id)) mapgl.removeLayer(id)
    if (mapgl.getLayer(`${id}_fill_extrusion`)) {
      mapgl.removeLayer(`${id}_fill_extrusion`)
    }
    if (mapgl.getLayer(`${id}_heatmap`)) mapgl.removeLayer(`${id}_heatmap`)
    if (mapgl.getLayer(`${id}_hexagon`)) mapgl.removeLayer(`${id}_hexagon`)
    if (mapgl.getLayer(`${id}_clusters`)) mapgl.removeLayer(`${id}_clusters`)
    if (mapgl.getLayer(`${id}_clusters_count`)) {
      mapgl.removeLayer(`${id}_clusters_count`)
    }
    if (mapgl.getLayer(`${id}_arrows`)) mapgl.removeLayer(`${id}_arrows`)
    if (mapgl.getLayer(`${id}_icons`)) mapgl.removeLayer(`${id}_icons`)
    if (mapgl.getSource(id)) mapgl.removeSource(id)
    if (mapgl.getSource(`${id}_clusters`)) mapgl.removeSource(`${id}_clusters`)
    if (mapgl.getSource(`${id}_diagram`)) mapgl.removeSource(`${id}_diagram`)
    if (mapgl.getSource(`${id}_heatmap`)) mapgl.removeSource(`${id}_heatmap`)
  }

  async toggleLayer(layer) {
    const { id } = layer
    const source_id = this.map.$store.state.profiles.sourceIdById[id]
    const { loadingLayers, activeLayers } = this.map.$store.state.map
    const isActive = activeLayers.includes(id)
    const isLoading = loadingLayers.includes(id)

    if (isLoading) return
    this.map.$store.commit('TOGGLE_LOADING_LAYER', id)

    if (isActive) {
      this.map.$store.commit('TOGGLE_ACTIVE_LAYER', id)
      this.map.$store.commit('TOGGLE_LOADING_LAYER', id)
      this.removeLayerById(id)
      if (this.map.$refs.mapPanels.layerSettingsId === id) {
        this.map.$refs.mapPanels.closeSecondPanel()
        this.map.$nextTick(() => this.map.mapgl.resize())
      }
    } else {
      try {
        const data = await getDatasourceById(this.map, source_id)
        const { datatype } = data

        switch (datatype) {
          case 'tile_layer':
            await this.addTileLayer(id, data)
            break
          default:
            await this.addGeojsonLayer(id, source_id, data)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        await this.map.$store.dispatch('SAVE_MAIN_USER_CONFIG')

        this.map.$store.commit('TOGGLE_LOADING_LAYER', id)
      }
    }
  }

  async addTileLayer(id, data) {
    const { mapgl } = this.map
    const { source_info } = data
    const { url } = source_info
    const index = getFirstSymbolId(mapgl)

    mapgl.addSource(id, {
      type: 'raster',
      tiles: [`${url}`],
      tileSize: 256,
      scheme: 'tms'
    })

    mapgl.addLayer({
      id,
      type: 'raster',
      source: id
    }, index)

    this.map.$store.commit('TOGGLE_ACTIVE_LAYER', id)
  }

  async addGeojsonLayer(id, source_id, data) {
    const { mapgl } = this.map
    const { datatype, geom_field, geom_type } = data
    // add listener to socket
    // if (this.map.$store.state.listeners.indexOf(id) === -1) {
    //   // checking if data exist
    //   this.map.$store.commit('ADD_LISTENER_ID', id)
    //   addListenerToSocket.call(this.map, id)
    // }
    // get fields and aggregation
    const fieldsUrl = `objectFields/${source_id}`
    const fieldsResponse = await this.map.$store.dispatch('GET_REQUEST', {
      url: fieldsUrl
    })
    this.map.objectFields[id] = parseObjectFields(fieldsResponse, false).map(
      item => ({
        ...item,
        children: [],
        fn_type: item.type === 'belongs_to' ? 'concat' : 'count',
        parent_id: source_id,
        source_name: item.source_name,
        isLoading: false
      })
    )
    this.map.$store.commit('SET_OBJECT_FIELDS', {
      source_id,
      fields: this.map.objectFields[id]
    })

    if (this.map.requestedFields[source_id] && !this.map.requestedFields[id]) {
      this.map.$set(
        this.map.requestedFields,
        id,
        this.map.requestedFields[source_id]
      )
    }

    const only = this.map.requestedFields[id] || []
    await getFieldsAggregation(this.map, only, id)

    // set requested fields
    if (!this.map.requestedFields[id]) {
      if (datatype === 'arc_layer') {
        this.map.requestedFields[id] = [
          'id',
          'geom',
          'to_geom',
          'value',
          'fromno',
          'matrixno'
        ]
      } else {
        this.map.requestedFields[id] = ['id', geom_field]
      }
    }

    const layerLoader = getLayerLoader(this, id, geom_type, geom_field)

    this.handlers[id] = debounce(layerLoader, 200)

    // set common layers handler
    mapgl.on('moveend', this.handlers[id])

    // set style config
    if (!this.map.styleConfig[id]) {
      this.map.$set(
        this.map.styleConfig,
        id,
        cloneDeep(styleConfigs[geom_type])
      )
    }

    if (this.map.styleConfig[id].order === undefined) {
      this.map.$set(this.map.styleConfig[id], 'order', {
        field: null,
        type: 'asc'
      })
    }

    // set map style config to store
    this.map.$store.commit('SET_MAP_STYLE_CONFIG', {
      fields: this.map.requestedFields,
      config: this.map.styleConfig,
      fieldsConfigs: this.map.objectFieldsConfigs
    })
    await layerLoader()

    // create additional layers
    const currentConfig = this.map.styleConfig[id]

    if (currentConfig.heatmap && currentConfig.heatmap.enabled) {
      this.toggleHeatmapLayer(id)
    }
    if (currentConfig.hexagon && currentConfig.hexagon.enabled) {
      this.toggleHexagonLayer(id)
    }
    if (currentConfig.arrows && currentConfig.arrows.enabled) {
      this.toggleArrows(id)
    }
    if (currentConfig.icon && currentConfig.icon.enabled) {
      this.toggleLayerIcon(id)
    }
    if (currentConfig.matrices && currentConfig.matrices.enabled) {
      this.map.controllers.matrices.toggleMatrices(id)
    }
    this.toggleCaptionLayer(id, currentConfig.popup)

    this.map.$store.commit('TOGGLE_ACTIVE_LAYER', id)
  }

  updatePopupLayers() {
    const { activeLayers } = this.map.$store.state.map

    if (activeLayers) {
      activeLayers.forEach(l => {
        const currentConfig = this.map.styleConfig[l]

        this.toggleCaptionLayer(l, currentConfig.popup)
      })
    }
  }

  async toggleHeatmapLayer(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_heatmap`
    const source = mapgl.getSource(layerId)
    const config = this.map.styleConfig[id].heatmap
    const source_id = this.map.$store.state.profiles.sourceIdById[id]

    if (source) {
      mapgl.removeLayer(layerId)
      mapgl.removeSource(layerId)
      config.enabled = false
    } else {
      this.map.mapLoading = true
      config.loading = true

      const filters = getRequestConfig(this.map, id, 'point')
      const url = `objectInfo/${source_id}?format=point_array&config=${JSON.stringify(filters)}`
      const { data } = await this.map.$store.dispatch('GET_REQUEST', {
        url
      })
      const layerData = pointArrayToGeojson(data?.filter(e => e))
      const color = createHeatmapColorValue(
        'heatmap-density',
        config.conditions
      )
      const options = {
        id: layerId,
        type: 'heatmap',
        source: layerId,
        paint: {
          'heatmap-color': color,
          'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20]
        }
      }
      mapgl.addSource(layerId, { type: 'geojson', data: layerData })
      if (mapgl.getLayer(id)) mapgl.addLayer(options, id)
      else mapgl.addLayer(options)
      config.enabled = true
      this.map.mapLoading = false
      config.loading = false
    }
  }

  async updateHeatmapLayer(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_heatmap`
    const source = mapgl.getSource(layerId)

    if (!source) return

    const config = this.map.styleConfig[id].heatmap
    const source_id = this.map.$store.state.profiles.sourceIdById[id]

    this.map.mapLoading = true
    config.loading = true

    const filters = getRequestConfig(this.map, id, 'point')
    const url = `objectInfo/${source_id}?format=point_array&config=${JSON.stringify(filters)}`
    const { data } = await this.map.$store.dispatch('GET_REQUEST', {
      url
    })
    const layerData = pointArrayToGeojson(data?.filter(e => e))

    source.setData(layerData)
    this.map.mapLoading = false
    config.loading = false
  }

  async toggleHexagonLayer(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_hexagon`
    const config = this.map.styleConfig[id].hexagon
    const mapLayer = mapgl.getLayer(layerId)
    const source_id = this.map.$store.state.profiles.sourceIdById[id]

    if (mapLayer) {
      mapgl.removeLayer(layerId)
      config.enabled = false
    } else {
      this.map.mapLoading = true
      config.loading = true
      const url = `objectInfo/${source_id}?format=point_array`
      const { data } = await this.map.$store.dispatch('GET_REQUEST', {
        url
      })
      const layer = this.map.controllers.heatmap.getHexagonLayer(id, data)
      mapgl.addLayer(layer)
      this.map.mapLoading = false
      config.loading = false
    }
  }

  toggleArrows(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_arrows`
    const currentConfig = this.map.styleConfig[id]
    const color = getLayerColor(this.map.styleConfig[id], 'line-color')

    if (mapgl.getLayer(layerId)) {
      mapgl.removeLayer(layerId)
      currentConfig.arrows.enabled = false
    } else {
      mapgl.addLayer({
        id: layerId,
        source: id,
        type: 'symbol',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': ['interpolate', ['linear'], ['zoom'], 12, 12, 22, 14],
          'symbol-spacing': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12,
            32,
            22,
            45
          ],
          'text-keep-upright': false
        },
        paint: {
          'text-color': color,
          'text-halo-color': '#fff',
          'text-halo-width': 1.3
        }
      })
    }
  }

  // point icon
  toggleLayerIcon(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_icons`
    const currentConfig = this.map.styleConfig[id]
    const { name, size } = currentConfig.icon

    if (mapgl.getLayer(layerId)) {
      mapgl.removeLayer(layerId)
      mapgl.setLayoutProperty(id, 'visibility', 'visible')
      currentConfig.icon.enabled = false
    } else {
      const isClusters = this.map.featuresInBboxCounts[id] > 2999

      mapgl.addLayer({
        id: layerId,
        source: isClusters ? `${id}_clusters` : id,
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': name,
          'icon-pitch-alignment': 'map',
          'icon-rotate': ['get', 'angle'],
          'icon-size': size / MAP_ICON_SIZE,
          'icon-ignore-placement': true
        }
      })
      mapgl.setLayoutProperty(id, 'visibility', 'none')

      if (this.layerMousemoveHandlers[id]) {
        mapgl.on('mousemove', layerId, this.layerMousemoveHandlers[id])
      }
      if (this.layerMouseoutHandlers[id]) {
        mapgl.on('mouseout', layerId, this.layerMouseoutHandlers[id])
      }
    }
  }

  async toggleCaptionLayer(id, config) {
    const { mapgl } = this.map
    const layerId = `${id}_caption`
    const mapLayer = mapgl.getLayer(layerId)
    const source = mapgl.getSource(id)

    const type = source?._data?.features?.[0]?.geometry?.type

    const placements = {
      LineString: 'line-center',
      MultiLineString: 'line-center'
    }
    const offsets = {
      Polygon: [0, 0],
      MultiPolygon: [0, 0]
    }

    if (mapLayer) {
      mapgl.removeLayer(layerId)
    }

    if (config?.enabled && config?.alwaysShow) {
      const [field] = config.fields

      if (field) {
        mapgl.addLayer({
          id: layerId,
          source: id,
          type: 'symbol',
          paint: {
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 1
          },
          layout: {
            'text-field': ['get', field],
            'text-anchor': 'top',
            'text-offset': offsets[type] || [0, 0.5],
            'text-size': 12,
            'symbol-placement': placements[type] || 'point',
            'text-letter-spacing': 0.05,
            'text-pitch-alignment': 'map',
            'text-rotation-alignment': 'auto'
          }
        })
      }
    }
  }

  changeIconSize(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_icons`
    const currentConfig = this.map.styleConfig[id]
    const { size } = currentConfig.icon

    if (!mapgl.getLayer(layerId)) return

    mapgl.setLayoutProperty(layerId, 'icon-size', size / MAP_ICON_SIZE)
  }

  changeIconImage(id) {
    const mapgl = this.map.mapgl
    const layerId = `${id}_icons`
    const currentConfig = this.map.styleConfig[id]
    const { name } = currentConfig.icon

    if (!mapgl.getLayer(layerId)) return

    mapgl.setLayoutProperty(layerId, 'icon-image', name)
  }

  async updateLayerData(id, fields) {
    const only = fields.filter(
      f => this.map.requestedFields[id].indexOf(f) === -1
    )

    if (only.length) {
      try {
        this.map.mapLoading = true
        this.map.$store.commit('SET_MAP_STYLE_CONFIG', {
          fields: this.map.requestedFields,
          config: this.map.styleConfig,
          fieldsConfigs: this.map.objectFieldsConfigs
        })
        // TODO в SAVE_MODULE_USER_CONFIG 3 аргументом нужно вставлять ID DS элемента (не source_id)
        // this.map.$store.dispatch("SAVE_MODULE_USER_CONFIG", {
        //   module: "map",
        //   id: "ID"
        // });
        this.map.requestedFields[id] = [
          ...this.map.requestedFields[id],
          ...only
        ]

        await getFieldsAggregation(this.map, only, id)
        await this.handlers[id]('filters')

        // this.map.$clientStore.insertData(source_id, data);
      } catch (error) {
        throw new Error(error)
      } finally {
        this.map.mapLoading = false
      }
    }
  }

  async changeFilterField(filter, id, value) {
    await this.updateLayerData(id, [value])
    this.updateHeatmapLayer(id)
    const source_id = this.map.$store.state.profiles.sourceIdById[id]
    const objectFields = this.map.$clientStore.getDataFields(source_id)
    const field = objectFields.find(f => f.title === value) || {}
    let { max, min, type } = field

    if (type === 'string' || type === 'boolean') {
      filter.loading = true
      filter.fieldType = type
      const url = `objectInfo/${source_id}?format=unique_field_count&field=${value}&limit=100`
      const { data } = await this.map.$store.dispatch('GET_REQUEST', { url })
      filter.allValues = data.filter(v => v.count)
      filter.values = filter.allValues.map(v => v.value)
      filter.loading = false
    } else {
      if (max !== undefined && min !== undefined) {
        let fieldType = 'number'
        if (field.type === 'datetime') {
          min = +new Date(min)
          max = +new Date(max)
          fieldType = 'datetime'
        }
        filter.range = [min, max]
        filter.min = min
        filter.max = max
        filter.fieldType = fieldType
      } else {
        filter.range = [0, 0]
        filter.min = 0
        filter.max = 0
      }

      try {
        filter.loading = true
        const url = `chunk/${source_id}?chunk_size=20&chunk_field=${value}`
        const { data } = await this.map.$store.dispatch('GET_REQUEST', { url })

        filter.chunks = data
        filter.allCount = this.map.objectsCounts[id]
        filter.loading = false

        this.handlers[id]('filters')
      } catch (error) {
        const title = 'Запрос не выполнен'
        const message = 'Не удалось загрузить распределение объектов'
        errorParser.call(this.map, error, message, title)
        filter.loading = false
        filter.field = ''
        filter.range = [0, 0]
        filter.min = 0
        filter.max = 0
        throw new Error(error)
      }
    }
  }
}
