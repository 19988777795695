<template>
  <div class="pot-table">
    <div class="pot-table__header">
      <div class="pot-table__header-title">
        <r-title>
          {{
            `Список транспорта с ТМЦ • ${isAdmin ? 'Диспетчер' : 'Оператор'}`
          }}
        </r-title>
      </div>
      <el-select
        :value="activeModule"
        class="r-select"
        @change="changeModule"
      >
        <el-option
          v-for="module in moduleList"
          :key="module.id"
          :value="module.id"
          :label="module.label"
        />
      </el-select>
      <r-button
        v-if="isAdmin"
        @click="createCargo"
      >
        {{ createBtnTitle }}
      </r-button>
    </div>
    <div class="pot-table__filters">
      <r-search-input
        placeholder="Искать в таблице"
        :filter-text="filterText"
        @change="filterText = $event"
      />
      <r-filter-set
        :filters="filters"
        @reset-all="resetFilters"
        @load="updateFilters"
        @reset="filterText = ''"
      />
    </div>
    <div class="pot-table__content">
      <r-table-list
        v-if="potVehicleList.length && !loading"
        bordered
        selectable
        :paginator="potVehicleList.length > 13"
        :data="tableData"
        :sort-by="grouping ? sortBy : null"
        :fields-names="fieldsExplanation"
        hide-empty-groups
        :include-columns="tableColumns"
        @click-handler="rowClick"
      />
      <div
        v-else
        class="pot-table__no-data"
      >
        <r-text>Нет ни одной заявки</r-text>
      </div>
    </div>
  </div>
</template>

<script>
import { fieldsExplanation, columns } from '../configs'
import { sortDataByField } from '@/utils'

const moduleList = [
  {
    id: 'vehicles',
    label: 'Автотранспорт'
  },
  {
    id: 'railway',
    label: 'ЖД'
  },
  {
    id: 'barges',
    label: 'Баржи'
  }
]

export default {
  props: {
    activeModule: {
      type: String,
      required: true
    },
    filters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      moduleList,
      grouping: true,
      filterText: '',
      fieldsExplanation
    }
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.pot1.isAdmin
    },
    createBtnTitle() {
      switch (this.activeModule) {
        case 'railway':
          return 'Добавить вагон и груз'
        case 'barges':
          return 'Добавить баржу и груз'
        default:
          return 'Добавить ТС и груз'
      }
    },
    statusList() {
      return this.$store.state.pot1.statusList || []
    },
    potVehicleList() {
      const list = this.$store.state.pot1.potVehicleList || []

      return sortDataByField(list, 'forecast_delivery', 'date')
    },
    tableColumns() {
      return columns?.[this.activeModule] || columns.default
    },
    tableData() {
      const searchFields = [
        'reg_number',
        'forecast_delivery',
        'vrc_full_name',
        'driver_phone',
        'driver_full_name',
        'carriage_no'
      ]

      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.potVehicleList || []

      return this.potVehicleList.filter(e => {
        const filtered = searchFields
          .map(f => e[f])
          .join('')
          ?.trim()
          ?.toLowerCase()
        return filtered.includes(query)
      })
    },
    sortBy() {
      const statusList = this.statusList.map(e => {
        return {
          value: e.id,
          title: e.name,
          order: e.ordered / 10
        }
      })

      return {
        propTitle: 'pot_status_id',
        values: statusList
      }
    }
  },
  methods: {
    changeModule(val) {
      this.loading = true
      this.filterText = ''
      this.$emit('change-module', val)
      setTimeout(() => (this.loading = false), 256)
    },
    async resetFilters() {
      this.$store.commit('SET_POT_FIELD', {
        field: 'updateData',
        value: true
      })

      const compName = this.isAdmin ? 'dispatcher' : 'logistic'

      this.$store.commit('SET_POT_FIELD', {
        field: `filters.${compName}_${this.activeModule}`,
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('SET_POT_FIELD', {
        field: 'updateData',
        value: true
      })

      const compName = this.isAdmin ? 'dispatcher' : 'logistic'

      this.$store.commit('SET_POT_FIELD', {
        field: `filters.${compName}_${this.activeModule}`,
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }
    },
    createCargo() {
      this.$store.commit('SET_POT_FIELD', {
        field: 'modalName',
        value: 'potNewCargoModal'
      })

      this.$store.commit('OPEN_MODAL_WINDOW', 'pot-modal-card')
    },
    rowClick(row) {
      this.$store.commit('SET_POT_FIELD', {
        field: 'cardId',
        value: row.id
      })
      this.$store.commit('SET_POT_FIELD', {
        field: 'modalName',
        value: 'potMainModal'
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'pot-modal-card')
    }
  }
}
</script>

<style lang="scss">
.pot-table {
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  min-height: 40vh;

  &__content {
    overflow: hidden;

    .r-table-list .cell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__no-data {
    margin-top: 100px;
    text-align: center;
  }

  &__filters {
    display: grid;
    grid-auto-flow: column;
    background-color: var(--bg_panel_primary);
    grid-template-columns: 300px auto;
    align-items: start;
    grid-gap: 1rem;
    padding: 0 1rem;

    .r-filter-set {
      padding-left: 0;
    }
  }

  &__header {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    height: 53px;
    justify-content: start;
    padding: 0.5rem 1rem;
    grid-gap: 2rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
    justify-items: start;

    &-title {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
    }
  }
}
</style>
