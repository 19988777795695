<template>
  <r-second-panel @close="closeCard">
    <div
      v-loading="loading"
      class="odd-card"
    >
      <r-panel-header title="Параметры объекта" />
      <card-controls
        :saving="saving"
        :has-rack="hasRack"
        :card-type="cardType"
        @open-rack="openRack"
        @close="closeCard"
        @save="saveObject"
        @delete="deleteObject"
        @flyTo="flyTo"
        @export="exportEvent"
      />
      <div
        v-show="!loading"
        class="odd-card__attributes"
      >
        <template v-if="cardType === 'signs' && !loading">
          <sign-item
            no-bg
            :sign="object"
          />
        </template>
        <event-attributes
          v-else-if="cardType === 'events'"
          :object="object"
          @save="saveObject"
        />
      </div>
    </div>
  </r-second-panel>
</template>

<script>
import CardControls from './card-controls'
import SignItem from './sign-item'
import EventAttributes from './event-attributes'
import { saveAs } from 'file-saver'

import { notifyFactory } from '@/utils'

export default {
  components: {
    CardControls,
    SignItem,
    EventAttributes
  },
  data() {
    return {
      loading: false,
      saving: false,
      object: {}
    }
  },
  computed: {
    hasRack() {
      return !!(this.cardType === 'signs' && this.object.rack_id)
    },
    cardId() {
      return this.$store.state.odd.cardId
    },
    cardType() {
      return this.$store.state.odd.cardType
    }
  },
  watch: {
    '$store.state.odd.needToSave'(value) {
      if (value) this.saveObject()
    },
    cardId(val) {
      if (val) {
        this.loadData()
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const cardId = String(this.cardId)
      try {
        this.loading = true
        const racksConfig = {
          where: [
            {
              field: 'geom',
              op: '!=',
              value: null
            }
          ],
          include: {
            signs: {}
          }
        }
        const { data: racks } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/telemetry.racks?scope=active&config=${JSON.stringify(racksConfig)}`
        })

        this.$store.commit('SET_ODD_FIELD', {
          field: 'racks',
          value: racks
        })

        const { ids } = this.$store.state.odd.model

        this.$store.commit('SET_ODD_EDITOR_PROP', {
          field: 'layerId',
          value: ids[this.cardType]
        })
        this.$store.commit('SET_ODD_EDITOR_PROP', {
          field: 'id',
          value: this.cardId
        })

        const config = {
          where: [
            {
              field: 'id',
              value: this.cardId,
              op: '='
            }
          ],
          include: {
            event_class: { only: ['name'] },
            road_block: {
              include: {
                recommendations: {}
              }
            },
            trafficaccident: {
              include: { injureds: {}, intruders: {}, corrective_actions: {} }
            }
          }
        }

        if (this.cardType === 'signs') {
          config.include.rack = {
            include: {
              link: {
                only: ['id', 'name']
              }
            }
          }
        }
        const url = `objectInfo/${ids[this.cardType]}?config=${JSON.stringify(
          config
        )}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        const object = data[cardId]

        if (object.rack) {
          object.projection = object.rack.geom
        }

        if (!object.rack_position) {
          object.rack_position = null
        }
        this.$set(this, 'object', object)

        this.$store.commit('SET_ODD_FIELD', {
          field: 'featureToEdit',
          value: {
            id: this.cardId,
            properties: object,
            geometry: object.geom
          }
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getSavingData() {
      const { featureToEdit } = this.$store.state.odd

      if (this.cardType === 'signs') {
        const { end_time } = this.object
        const endTime = end_time ? this.$ritmDate.toIso(end_time) : null

        return {
          ...this.object,
          end_time: endTime,
          projection: featureToEdit.properties.projection,
          geom: featureToEdit.geometry
        }
      }
      // Fix this with backend
      if (this.object.trafficaccident) {
        this.object.trafficaccident.datetime = this.$ritmDate.toIso(this.object.start_time)
      }
      // Fix this with backend

      return {
        ...this.object,
        start_time: this.$ritmDate.toIso(this.object.start_time),
        end_time: this.$ritmDate.toIso(this.object.end_time),
        geom: featureToEdit.geometry
      }
    },
    async saveObject() {
      try {
        this.saving = true

        const { model } = this.$store.state.odd
        const { ids } = model
        const url = `objectInfo/${ids[this.cardType]}`
        const data = this.getSavingData()

        await this.$store.dispatch('POST_REQUEST', { url, data })

        const title = 'Сохранение выполнено'
        const message = 'Объект успешно сохранен'
        this.$notify(notifyFactory('succcess', title, message))

        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToUpdate',
          value: true
        })
        this.loadData()
      } catch (e) {
        console.error(e)
        const title = 'Сохранение не выполнено'
        const message = 'Не удалось сохранить объект'
        this.$notify(notifyFactory('error', title, message))
      } finally {
        this.$store.commit('CLOSE_MODAL_WINDOW')
        this.saving = false
        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToSave',
          value: false
        })
      }
    },
    async deleteObject() {
      try {
        const { ids } = this.$store.state.odd.model
        const url = `objectInfo/${ids[this.cardType]}?id=${this.cardId}`
        await this.$store.dispatch('DELETE_REQUEST', { url })

        this.$store.commit('SET_ODD_FIELD', {
          field: 'needToUpdate',
          value: true
        })
        this.closeCard()
      } catch (e) {
        console.error(e)
      }
    },
    async exportEvent() {
      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_BLOB_REQUEST', {
          url: `event?format=export&id=${this.object.id}`
        })

        saveAs(data, `${this.object.name}.docx`)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    flyTo() {
      this.$store.commit('SET_ODD_FIELD', {
        field: 'flyToGeom',
        value: this.object.geom
      })
    },
    closeCard() {
      this.$store.commit('SET_ODD_EDITOR_PROP', {
        field: 'editByRoute',
        value: false
      })
      this.$store.commit('SET_ODD_FIELD', { field: 'cardId', value: null })
    },
    openRack() {
      const feature = {
        id: this.object.id,
        geometry: this.object.geom,
        rack: this.object.rack_id,
        properties: this.object
      }
      const editorState = {
        id: null,
        layerId: null,
        type: 'signs',
        createdObject: feature
      }
      this.$store.commit('SET_ODD_EDITOR_STATE', editorState)

      this.$store.commit('SET_ODD_FIELD', {
        field: 'newCardId',
        value: feature.id
      })
    }
  }
}
</script>

<style lang="scss">
.odd-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__attributes {
    padding: 0.5rem;
    height: 100%;
    overflow: auto;
  }

  &__footer {
    padding: 0.5rem;
    display: grid;
  }
}
</style>
