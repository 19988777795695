<template>
  <div class="mt-track-filters">
    <div class="mt-track-filters__item">
      <r-text type="caption">
        Начало
      </r-text>
      <r-date-picker
        v-model="interval.from"
        class="r-date-picker"
        type="datetime"
        size="mini"
        name="datetime"
        format="dd.MM.yyyy HH:mm"
        :clearable="false"
        :picker-options="toTimeOptions"
        @change="loadTrack"
      />
    </div>
    <div class="mt-track-filters__item">
      <r-text type="caption">
        Окончание
      </r-text>
      <r-date-picker
        v-model="interval.to"
        class="r-date-picker"
        type="datetime"
        size="mini"
        name="datetime"
        format="dd.MM.yyyy HH:mm"
        :clearable="false"
        :picker-options="toTimeOptions"
        @change="loadTrack"
      />
    </div>
    <div class="mt-track-filters__item">
      <r-button
        type="primary"
        :loading="loading"
        @click="toggleTrack"
      >
        {{ trackButtonText }}
      </r-button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    interval: {
      type: Object,
      required: true
    },
    telematics: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toTimeOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  computed: {
    trackButtonText() {
      if (this.loading) {
        return this.telematics ? 'Загрузка...' : 'Загрузка трека...'
      }

      if (this.show) {
        return this.telematics ? 'Скрыть телематику' : 'Скрыть трек'
      }

      return this.telematics ? 'Показать телематику' : 'Показать трек'
    }
  },
  methods: {
    loadTrack() {
      this.$emit('load')
    },
    toggleTrack() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss">
.mt-track-filters {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  align-items: end;
  justify-content: start;

  .r-date-picker.el-input {
    width: 180px;
  }
}
</style>
