<template>
  <div
    v-loading="loading"
    class="an-content"
  >
    <an-content-header />
    <an-content-controls v-if="!report.noOptions" />
    <an-content-layout
      v-if="!loading"
    />
  </div>
</template>

<script>
import anContentHeader from './an-content-header'
import anContentControls from './an-content-controls'
import anContentLayout from './an-content-layout'

import { dataRequest } from '../../helpers'
import { urlReplaceParams } from '@/utils'

export default {
  components: {
    anContentHeader,
    anContentControls,
    anContentLayout
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report || null
    },
    reportUrl() {
      return this.$store.state.analytics.reportUrl || null
    },
    pagy() {
      return this.$store.state.analytics.pagy || null
    },
    workspaceType() {
      return this.$store.state.analytics.workspaceType
    }
  },
  watch: {
    reportUrl() {
      this.getData()
    },
    pagy() {
      const url = urlReplaceParams(this.reportUrl, { limit: this.pagy.pageSize, page: this.pagy.currentPage })
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'reportUrl',
        value: url
      })
    },
    workspaceType() {
      this.loading = true

      setTimeout(() => {
        this.loading = false
      }, 512)
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      if (!this.reportUrl) return

      this.loading = true

      if (!Array.isArray(this.reportUrl)) {
        try {
          const data = await dataRequest(this, this.reportUrl)

          if (Array.isArray(data)) {
            this.$store.commit('ANALYTICS_SET_FIELD', {
              field: 'data',
              value: data
            })
          } else {
            this.$store.commit('ANALYTICS_SET_FIELD', {
              field: 'data',
              value: data.data
            })
            this.$store.commit('ANALYTICS_SET_FIELD', {
              field: 'pagyResponse',
              value: data.pagy
            })
          }
        } catch (e) {
          throw new Error(e)
        } finally {
          this.loading = false
        }
      } else {
        try {
          const reportData = []

          await Promise.all(
            this.reportUrl.map(async url => {
              const { data } = await this.$store.dispatch('GET_REQUEST', {
                url
              })

              if (Array.isArray(data)) {
                reportData.push(...data)
              } else if (data) {
                reportData.push(...Object.values(data))
              }
            })
          )

          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'data',
            value: reportData
          })
        } catch (e) {
          throw new Error(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.an-content {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
</style>
