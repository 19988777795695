<template>
  <div id="an-map">
    <r-map-tools
      v-if="mapgl"
      :tools="['baselayers', 'screenshot']"
      :baselayer-id="baselayerId"
      :map="mapgl"
      @toggle-base-layer="toggleBaselayer"
    />
    <div
      v-if="hasInfoLayers && ready"
      class="map__layers"
    >
      <r-info-layers
        ref="infoLayers"
        :mapgl="mapgl"
        :module="module"
        :enabled-layers="['geozones']"
      />
    </div>
  </div>
</template>

<script>
import { initMap } from './core/'
import extent from 'turf-extent'

export default {
  data() {
    return {
      mapgl: {},
      module: 'map-analytic',
      trackSource: 'trackLayer',
      ready: false,
      controllers: {},
      mapData: {},
      activeObjectCache: null
    }
  },
  computed: {
    report() {
      return this.$store.state.analytics.report
    },
    reportData() {
      return this.$store.state.analytics.data
    },
    activeObject() {
      return this.$store.state.analytics.activeObject
    },
    baselayerId() {
      return this.$store.state.mapConfigs?.baselayers?.analytics || 5
    },
    hasInfoLayers() {
      return this.report?.infoLayers
    },
    trackData() {
      return this.$store.state.analytics.trackData
    }
  },
  watch: {
    '$store.state.analytics.flyToGeom': function(geom) {
      if (!geom) return

      const bounds = extent(geom)

      this.mapgl.fitBounds(bounds, {
        padding: 20,
        maxZoom: 16
      })
      this.$store.commit('ANALYTICS_SET_FIELD', {
        field: 'flyToGeom',
        value: null
      })
    },
    activeObject: {
      handler: function(object) {
        if (!object || !this.controllers.active_object) return

        if (object.id === this.activeObjectCache?.id) {
          this.controllers.active_object.removeLayer()
          this.activeObjectCache = null
        } else {
          this.controllers.active_object.addLayer(object)
          this.activeObjectCache = object
        }
        this.$store.commit('ANALYTICS_SET_FIELD', {
          field: 'activeObject',
          value: null
        })
      },
      deep: true
    },
    trackData() {
      this.toggleTrack()
    }
  },
  mounted() {
    this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
      field: 'activeLayers',
      module: this.module,
      value: ['bfbc88aa-6085-4a9e-9be2-c288c579c494']
    })
    initMap(this, this.callback)
  },
  methods: {
    callback() {
      this.ready = true
    },
    async toggleBaselayer({ id }) {
      this.ready = false
      this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
        field: 'baselayers',
        module: 'analytics',
        value: id
      })
      initMap(this, this.callback)
    },
    toggleTrack() {
      if (this.trackData?.length) {
        this.addTrack()
      } else {
        this.removeTrack()
      }
    },
    addTrack() {
      const source = this.mapgl.getSource(this.trackSource)

      const data = {
        type: 'FeatureCollection',
        features: this.trackData
      }

      if (source) {
        source.setData(data)
      } else {
        this.mapgl.addSource(this.trackSource, {
          type: 'geojson',
          data: data
        })

        this.mapgl.addLayer(
          {
            id: this.trackSource,
            source: this.trackSource,
            type: 'line',
            layout: {
              'line-cap': 'round',
              'line-join': 'round'
            },
            paint: {
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#55b77e',
                [
                  'step',
                  ['get', 'speed'],
                  'rgb(180,220,177)',
                  40,
                  'rgba(153,213,148, 1)',
                  60,
                  'rgb(236,209,158)',
                  70,
                  'rgb(248,216,127)',
                  90,
                  'rgba(252,141,89, 1)',
                  110,
                  'rgba(213,62,79, 1)'
                ]
              ],
              'line-width': [
                'match',
                ['get', 'type'],
                'active',
                ['case', ['boolean', ['feature-state', 'hover'], false], 12, 6],
                3
              ],
              'line-opacity': ['match', ['get', 'type'], 'active', 0.9, 0.2]
            }
          }
        )

        this.mapgl.addLayer(
          {
            id: `${this.trackSource}_arrows`,
            source: this.trackSource,
            type: 'symbol',
            layout: {
              'symbol-placement': 'line',
              'text-field': '▶',
              'text-size': ['interpolate', ['linear'], ['zoom'], 12, 10, 22, 12],
              'symbol-spacing': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                32,
                22,
                45
              ],
              'text-keep-upright': false,
              'text-allow-overlap': true,
              'icon-allow-overlap': true
            },
            paint: {
              'text-color': '#2e93fe',
              'text-halo-color': '#ffffff',
              'text-halo-width': 0.8
            }
          }
        )
      }
    },
    removeTrack() {
      if (this.mapgl.getLayer(this.trackSource)) {
        this.mapgl.removeLayer(this.trackSource)
      }
      if (this.mapgl.getLayer(`${this.trackSource}_arrows`)) {
        this.mapgl.removeLayer(`${this.trackSource}_arrows`)
      }
      if (this.mapgl.getSource(this.trackSource)) {
        this.mapgl.removeSource(this.trackSource)
      }
    }
  }
}
</script>

<style lang="scss">
#an-map {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
}
.map__layers {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
</style>
