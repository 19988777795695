export const prepareVehicleData = function(data, checkpoints, warehouses) {
  const vehicleData = Object.values(data)[0]

  if (!vehicleData) return null

  vehicleData.checkpoint = checkpoints[vehicleData.checkpoint_id]?.name || null
  vehicleData.warehouse = warehouses[vehicleData.warehouse_id]?.name || null
  vehicleData.cargoes = vehicleData.cargoes[0] || {}

  vehicleData.pot_coordinates = [128.19582743748515, 51.492249676612175]
  vehicleData.checkpoint_geom = checkpoints[vehicleData.checkpoint_id]?.geom || null
  vehicleData.warehouse_geom = warehouses[vehicleData.warehouse_id]?.geom || null
  vehicleData.coordinates = []
  vehicleData.coordinates.push(vehicleData.pot_coordinates)

  if (vehicleData.checkpoint_geom?.coordinates) vehicleData.coordinates.push(vehicleData.checkpoint_geom.coordinates)
  if (vehicleData.warehouse_geom?.coordinates) vehicleData.coordinates.push(vehicleData.warehouse_geom.coordinates)

  vehicleData.pot_info = {
    title: 'ПОТ',
    geom: {
      type: 'Point',
      coordinates: vehicleData.pot_coordinates
    }
  }

  vehicleData.checkpoint_info = {
    title: vehicleData.checkpoint || '---',
    geom: vehicleData.checkpoint_geom || null
  }

  vehicleData.warehouse_info = {
    title: vehicleData.warehouse || '---',
    geom: vehicleData.warehouse_geom || null
  }

  return vehicleData
}
