<template>
  <div class="an-content-header">
    <r-button
      simple
      icon="arrow-back"
      @click="clickHandler('return')"
    >
      К списку отчётов
    </r-button>
    <r-title type="title-2">
      {{ `${reportTitle} (Количество объектов: ${dataLength})` }}
    </r-title>
    <r-block
      no-padding
      col
    >
      <r-button
        :loading="loading"
        type="primary"
        @click="makeScreenshot"
      >
        Сделать скриншот
      </r-button>
      <an-export v-if="!report.noExport" />
    </r-block>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import { dataURLtoBlob } from '@/utils'
import anExport from '../an-export/an-export'

export default {
  components: {
    anExport
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    activeId() {
      return this.$store.state.analytics.activeId
    },
    pagyResponse() {
      return this.$store.state.analytics.pagyResponse || null
    },
    report() {
      return this.$store.state.analytics.report || null
    },
    dataLength() {
      if (this.pagyResponse) {
        return this.pagyResponse.count || 0
      } else {
        return this.$store.state.analytics.dataLength || 0
      }
    },
    reportTitle() {
      return this.report.title || ''
    }
  },
  methods: {
    clickHandler(action) {
      switch (action) {
        case 'export':
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'showExport',
            value: true
          })
          break
        default:
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'reportUrl',
            value: null
          })
          this.$store.commit('ANALYTICS_SET_FIELD', {
            field: 'workspaceType',
            value: 'table'
          })
          break
      }
    },
    async makeScreenshot() {
      let tools, layers

      try {
        this.loading = true
        const content = document.getElementById('reportContent')

        tools = content.querySelector('.map-tools')
        layers = content.querySelector('.map__layers')

        if (tools) {
          tools.style.opacity = 0
        }
        if (layers) {
          layers.style.opacity = 0
        }

        const canvas = await html2canvas(content)

        const base64 = canvas.toDataURL('image/png')
        const blob = dataURLtoBlob(base64)

        saveAs(blob, this.reportName)
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
        if (tools) {
          tools.style.opacity = 1
        }
        if (layers) {
          layers.style.opacity = 1
        }
      }
    }
  }
}
</script>

<style lang="scss">
.an-content-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid;
  border-color: var(--dividers_low_contrast);

  > * {
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }

  .r-title {
    margin-right: auto;
  }
}
</style>
